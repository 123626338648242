import React, { useState, useEffect } from "react";
import "./BotonesStyles.css";
import { db, auth } from "../../../firebase";
import firebase from "firebase/app";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { InputLabel, FormControl, Select, MenuItem } from "@material-ui/core";
import { Button, Container, Form, Modal } from "react-bootstrap";
import axios from "axios";
import AlertError from "../Alerta/AlertError";
import Path from "../../../Constantes/Constantes";

const BotonesSocial = ({
  setRedirect,
  setShowLogin,
  setUsuarioData,
  setIsDataLoading,
}) => {
  // => Estado Modal
  const [show, setShow] = useState(false);
  const [nacionalidad, setNacionalidad] = useState("");

  // => Estado tipo de usuario para modal
  const [tipo, setTipo] = useState("Suscriptor");
  const [userParaBuscar, setUserParaBuscar] = useState("");
  const [error, setError] = useState("");

  // => Funciones modal mostrar/cerrar
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function obtenerNacionalidad() {
    await axios.get(`http://ip-api.com/json`).then(async (res) => {
      const data = await res.data;
      setNacionalidad(data);
    });
  }

  useEffect(() => {
    obtenerNacionalidad();
  }, []);

  const ErrorData = {
    icon: "error",
    title: "Oops..",
    type: "error",
    text: error.toString(),
    footer: `<a hr href="https://api.whatsapp.com/message/JPG7W4I2LY4YJ1"
            target="blank"ef>No puedes logearte?</a>`,
  };

  let provider = new firebase.auth.GoogleAuthProvider();

  async function authenticar(value) {
    if (value === "google") {
      provider = new firebase.auth.GoogleAuthProvider();

      provider.addScope("profile");
    }
    if (value === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider();
    }
    auth
      .signInWithPopup(provider)
      .then(async (result) => {
        redireccionamientoConUser(result.user);
      })
      .catch((err) => {
        if (err.code == "auth/popup-closed-by-user") {
          setError("Por favor no cierre la ventana emergente y espere");
        } else {
          setError(err.code);
        }
        setError("");
      });
  }

  async function redireccionamientoConUser(resultuser) {
    buscarOSetearEnFireStore(resultuser).then((usuario) => {
      // console.log(usuario);
      setUsuarioData(usuario);
      setIsDataLoading(true);
      setShowLogin(false);
      setRedirect(`/`);
    });
  }

  //buscar y registrar user
  const buscarOSetearEnFireStore = async (user) => {
    var resultado = "";
    const result = await db
      .collection("users")
      .where("email", "==", user.email)
      .get();

    if (result.docs.length === 0) {
      // Set new data since this is a new user
      //Hay que permitir para poder acceder a la location con user_location , de momento esta fijado Argentina
      var usua = {
        id: user.uid,
        nombre: user.displayName,
        email: user.email,
        nacionalidad: nacionalidad.city + " , " + nacionalidad.country,
        telefono: user.phoneNumber,
        foto: user.photoURL,
        cuentaCreada: new Date(),
        tipo: "Usuario",
      };
      await db.collection("users").doc(user.uid).set(usua);
      return usua;
    } else {
      result.forEach((doc) => {
        resultado = doc.data();
      });
      return resultado;
    }
  };

  function mostrarModalTipo(resultuser) {
    handleShow();
    setUserParaBuscar(resultuser);
  }

  return (
    <>
      {error != "" ? <AlertError {...ErrorData} /> : <div></div>}
      <div className="contenedorBotones d-flex flex-column m-5">
        <div className="separador-btn">
          <FacebookLoginButton
            text="Ingresá con Facebook"
            onClick={() => authenticar("facebook")}
            className="btn-login-social"
            align={"center"}
          />
        </div>
        <div className="separador-btn">
          <GoogleLoginButton
            text="Ingresá con Google"
            onClick={() => authenticar("google")}
            className="btn-login-social"
            align={"center"}
          />
        </div>
      </div>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bienvenido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Es la primera vez que ingresa a la plataforma.
          <br />
          ¿Qué tipo de usuario es?!
          <br />
          <FormControl
            style={{ minWidth: 250 }}
            variant="outlined"
            className="mt-3"
          >
            <InputLabel id="demo-simple-select-label">
              Seleccion el tipo de usuario
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="tipo"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <MenuItem value="Entrenador">Entrenador</MenuItem>
              <MenuItem value="Suscriptor">Suscriptor</MenuItem>
            </Select>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            variant="warning"
            onClick={() => redireccionamientoConUser(userParaBuscar)}
          >
            Enviar
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default BotonesSocial;
