import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Alert,
  Modal,
  Dropdown,
} from "react-bootstrap";
import "./Login.css";
import { Redirect, Link, Route } from "react-router-dom";
import AlertError from "./Alerta/AlertError";
import BotonesSocial from "./BotonesSocial/BotonesSocial";
import { Register } from "../register/Register";
import { useForm } from "../../hooks/useForm";
import { auth, loginUser } from "../../firebase";
import PoliticasDePrivacidad from "../PoliticasDePrivacidad/PoliticasDePrivacidad";

const formData = {
  email: '',
  password: '',
  nombre: '',
  nacionalidad: '',
  telefono: '',
  foto: '',
  cuentaCreada: new Date(),
  tipo: "Usuario",
}

const formValidations = {
  email: [ (value) => value.includes('@'), 'El correo debe ser un formato válido.'],
  password: [ (value) => value.length >= 6, 'El password debe de tener al menos 6 caracteres.']
}

function Login({ setShowLogin, setIsDataLoading, setUsuarioData }) {
  const [redirect, setRedirect] = useState(null);
  // => Estado mensaje de error
  const [errorMsg, setErrorMsg] = useState(null);
  // => Error por pantalla
  const [error, setError] = useState("");

  const [crear, setCrear] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(false);
  }

  const { 
    formState, email, password, onInputChange,
    isFormValid, emailValid, passwordValid, 
  } = useForm( formData, formValidations );

  useEffect(() => {

    setError('')
        
  }, [emailValid, passwordValid, error])

  const onSubmit = ( event ) => {
    event.preventDefault();
    // setFormSubmitted(true);
    
    if ( !isFormValid ) return setErrorMessage(true) ;
  
    auth.signInWithEmailAndPassword( email, password )
          // .then(resp => {
          //   if( resp.code ){
          //     setError( resp.message )
          //   }
          // })
          .then(  resp => {
            if(!error){
              setShowLogin(false)
            }
          } )
          .catch( e=> setearCaseError( e.code ) )

          //  auth.onAuthStateChanged( user =>{

          //     if(user){
          //       console.log(user)
          //     }
          //   } )
 
          
  }

  const ErrorData = {
    icon: "error",
    title: "Oops..",
    type: "error",
    text: error.toString(),
    footer: "<a href>Why do I have this issue?</a>",
  };
  const setearCaseError = (e) => {
    switch (e) {
      case "auth/wrong-password":
        setError("Contraseña Incorrecta");
        break;
      case "auth/user-not-found":
        setError("Email no encontrado u erroneo");
        break;
      case "auth/invalid-email":
        setError("Email no encontrado u erroneo");
        break;
      case "auth/too-many-requests":
        setError("Intentos maximos superados, intente loguear en unos minutos");
        break;
      default:
        break;
    }
  };

  // => Redirección
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Container className="d-flex justify-content-center align-items-center flex-column login-container">

    { !crear ?(

      <>
      {/* <h1 className="mt-5">Ingresa con tus redes!</h1> */}
        {/* <h3>
          Si es la primera vez, usa una de las siguientes redes sociales para
          registrearte en nuestra plataforma
        </h3> */}
        <h1>Inicia sesion</h1>

        <form onSubmit={ onSubmit }>

        <div container className='login'>

          <div item xs={ 12 }  className="input-container">
            <input 
              type="email" 
              placeholder='Ingrese correo' 
              name="email"
              value={ email }
              onChange={ onInputChange }
            />
            {errorMessage &&
        
                <div className="error-messaje">{emailValid}</div>
            }
          </div>
          <div item xs={ 12 }  className="input-container">
            <input 
              type="password" 
              placeholder='Ingrese password' 
              name="password"
              value={ password }
              onChange={ onInputChange }
            />
              {errorMessage &&
        
                  <div className="error-messaje"><span>{passwordValid}</span></div>
              }
          </div>

          <div    style={{ textAlign: 'center', margin: '15px 0' }}>

            <button 
                // disabled={ isCheckingAuthentication }

                className='btn-primary-'
                type="submit"
                variant='contained' 
                fullWidth>
                Iniciar sesión
            </button>
          </div>


        </div>
          

        </form>

      <h3 > ¿No tenés cuenta? <span className="btn-log" onClick={ ()=> setCrear( !crear ) }>Crea una!</span></h3>

        <h3 style={{  margin: " 20px 0 -35px 0"}}>
           puedes elegir una de las siguientes redes sociales para
          registrarte en nuestra plataforma
        </h3>

        
      {error != "" ? <AlertError {...ErrorData} /> : <div></div>}

      <BotonesSocial
        setShowLogin={setShowLogin}
        setRedirect={setRedirect}
        setIsDataLoading={setIsDataLoading}
        setUsuarioData={setUsuarioData}
      />

      <h3>Al iniciar session aceptás nuestras <span className="politicas-uso" onClick={ () => setShow(true) }>politicas de uso y privacidad</span>  </h3>



      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Politicas de uso y privacidad</Modal.Title>
        </Modal.Header>

        <Modal.Body>
              <PoliticasDePrivacidad />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={ () => setShow(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>       


      </>
    ):
    (
      <>
      <h1 className="mt-5">Crear cuenta!</h1>

      <Register  setShowLogin={setShowLogin} setUsuarioData={setUsuarioData}/>

      <h3 > ¿Ya tenés cuenta? <span className="btn-log" onClick={ ()=> setCrear( !crear ) }>Inicia sesión!</span></h3>
      </>
    )
    
    }



    </Container>
  );
}

export default Login;
