
export const types = {
    setProductsCart : "[Cart] SET_PRODUCTS",
    removeProductCart : "[Cart] REMOVE_PRODUCTS",
    clearCart: "[Cart] REMOVE_ALL_PRODUCTS",

    getDolar: "[Ui] GET_DOLAR_PRICE",
    getDolarComision: '[Ui] GET_DOLAR_COMISION_PRICE',
    setCurrency: '[Ui] SET_CURRENCY '
}

