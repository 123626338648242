import React from "react";
import Compyright from "./Copyright";
import Social from "../Social/Social";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer ">
      <div className=" footerConteiner">
        <div className="row footer__row">
          <div className="copyright col-sm-12 col-lg-4 col-md-12 mb-4 mb-md-0">
            <Compyright />
          </div>
          <div className="desarrollado col-sm-12 col-lg-4 col-md-6 mb-4 mb-md-0">
            <h6 className="text-uppercase  textMidle">
              Blazer Store
              {/* Powered by
              <br />
              <a href="https://www.linkedin.com/in/nahuel-scerca-a619a018b">
                Nahuel Scerca
              </a> */}
              <br />
            </h6>
          </div>
          <div className="socialFooter  col-sm-12 col-lg-4 col-md-6 mb-4 mb-md-0">
            <Social noDispose={true} />
          </div>
        </div>
      </div>
    </div>
  );
}
