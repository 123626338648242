import React, { useState, useEffect } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Carrousel from "./Carrousel/Carrousel";
import CoverInfo from "./CovertInfo/CoverInfo";

import didi from "../../img/Players/didi.jpg";
import milnueve from "../../img/Players/1962.jpg";
import dillion from "../../img/Players/dillion.jpg";
import owen from "../../img/Players/owen.jpg";
import rever from "../../img/Players/rever.jpg";
import tom1jed from "../../img/Players/tom1jed.jpg";

import cedrola from "../../img/Streamers/cedrola.jpg";
import imicaelaxx from "../../img/Streamers/imicaelaxx.jpg";

import sett from "../../img/Youtubers/sett.jpg";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import FloatChat from "../../components/FloatChat/FloatChat";

const streamers = [
  // {
  //   src: branuel,
  //   insta: "https://www.instagram.com/branuelcs/",
  //   twitch: "https://www.twitch.tv/branuelcsgo",
  //   tw: "https://twitter.com/branuelcs",
  // },
  {
    src: cedrola,
    insta: "https://www.instagram.com/cedrolaa",
    twitch: "https://www.twitch.tv/cedrolaa",
    tw: "https://twitter.com/CedrolaTV",
  },
  {
    src: imicaelaxx,
    insta: "https://www.instagram.com/imicaelax",
    twitch: "https://www.twitch.tv/imicaelax",
    tw: "https://twitter.com/imicaelax",
  },
];

const youtubers = [
  {
    src: sett,
    insta: "https://www.instagram.com/settcsgo/",
    twitch: "https://www.twitch.tv/settcsgo",
    tw: "https://twitter.com/settcsgo",
    yt: "https://www.youtube.com/sett1ng",
  },
  // {
  //   src: batata,
  //   insta: "https://www.instagram.com/batatabionica",
  //   twitch: "https://www.twitch.tv/batatabionica",
  //   tw: "https://twitter.com/batatabionica",
  //   yt: "https://www.youtube.com/batatabionica",
  // },
];

const players = [
  {
    src: didi,
    insta: "https://www.instagram.com/arieldidicsgo/",
    twitch: "https://www.twitch.tv/arieldidi",
    tw: "https://twitter.com/arieldidicsgo",
  },
  {
    src: milnueve,
    insta: "https://www.instagram.com/gab.snl",
    twitch: "https://www.twitch.tv/1962cs",
    tw: "https://twitter.com/1962cs",
  },
  {
    src: dillion,
    insta: "https://www.instagram.com/arieldidicsgo/",
    twitch: "https://www.twitch.tv/dillion10",
    tw: "https://twitter.com/juannreyess",
  },
  {
    src: owen,
    insta: "https://www.instagram.com/mathiasowen",
    twitch: "https://www.twitch.tv/owensinhom",
    tw: "https://twitter.com/owensinhom",
  },
  {
    src: tom1jed,
    insta: "https://www.instagram.com/tom1rivero",
    twitch: "https://www.twitch.tv/tom1jed",
    tw: "https://twitter.com/tom1jed",
  },
  {
    src: rever,
    insta: "https://www.instagram.com/reversive10/",
    twitch: "https://www.twitch.tv/reversivecs",
    tw: "https://twitter.com/reversive_10",
  },
];

const twlist = [
  {
    id1: "1390428403925454849",
    id2: "1387442821871898624",
    id3: "1385998948503011331",
  },
  {
    id1: "1384283880258097158", //medio
    id2: "1380727709660241921", //grande
    id3: "1377782901178253316", //chico
  },
  {
    id1: "1377308788538114052", //chico
    id2: "1376950051101147137", //grande
    id3: "1356609117608169474", //chico
    //id3: "1374568118647988224",
  },
  {
    id1: "1374431937738108941", //chico
    id2: "1370403704139096066", //grande
    id3: "1356048468175958019", //chico
  },
  {
    id1: "1364687333879132160", //medio
    id2: "1366112861148356618", //grande
    id3: "1363662511296380930", //chico
  },
  // {
  //   id1: "1362914525952040971",
  // },
  {
    id1: "1358799485577666562", //grande

    id2: "1370134070940856322", //grande
    id3: "1353419118440046592", //chicomediano
  },
];

const Home = ({ userAuth, sponsors }) => {
  const [mostrarTiendaItem, setMostrarTiendaItem] = useState(false);

  function hadleTiendaShow() {
    setMostrarTiendaItem(true);
  }
  function scroll(e) {}

  function handleClose() {
    setMostrarTiendaItem(false);
  }
  useEffect(() => {
    if (sponsors) {
      const element = document.querySelector("#SponsorId");
      const topPos = element.getBoundingClientRect().top + window.pageYOffset;
      setTimeout(() => {
        window.scrollTo({
          top: topPos,
          behavior: "smooth",
        });
      }, 600);

      console.log("hacia sponsossssersr");
    }
  }, []);
  return (
    <>
      <div className="contenedor-home-padre-unico">
        {/* <TituloPaginaV3 titulo="Home" /> */}

        <div className="home-contenedor-padre">
          <div className="background-white-home">
            <div className="first-item-home">
              <div className="first-item-home-difum"></div>
              {/* <img src={imgFirst} alt="s" /> */}
              <a className="bajar-sin-estilos" href="#SponsorId">
                <div className="flecha-bajar">
                  <ExpandMoreIcon />
                </div>
              </a>
              {userAuth ? (
                <Link to="/Tienda" className="bajar-sin-estilos">
                  <div className="boton-tienda">Tienda</div>
                </Link>
              ) : (
                <a
                  className="bajar-sin-estilos"
                  onClick={() => hadleTiendaShow()}
                >
                  <div className="boton-tienda">Tienda</div>
                </a>
              )}
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={mostrarTiendaItem}
                message="Inicia sesion ahora!"
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  onClose={handleClose}
                  severity="info"
                >
                  Inicia sesion ahora!
                </MuiAlert>
              </Snackbar>
            </div>
          </div>

          <div id="SponsorId"></div>
          <Carrousel title="CSGO Profesional Players" list={players} />
          <Carrousel title="Streamers" list={streamers} />
          <Carrousel title="Youtubers" list={youtubers} />
          <CoverInfo />
          <Carrousel title="OPINIONES DE NUESTROS CLIENTES" listTw={twlist} />

          <a
            className="bajar-sin-estilos"
            onClick={(e) => scroll(e)}
            href="#InicioRef"
          >
            <div className="subir-sin-estilos">
              <ExpandLessIcon />
            </div>
          </a>
          {/* <Novedades /> */}
          {/* <Contacto /> */}
          <FloatChat />
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default Home;
