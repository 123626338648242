import { useReducer } from "react"
import { UiContext } from "./UiContext"
import { uiReducer } from "./uiReducer"
import { types } from "../types"

const initialState = {
  dolar: null,
  dolarComision: null, 
  currency: 'ARS'
}

export const UiProvider = ({children}) => {

 const [ UiState, dispatch ] = useReducer( uiReducer, initialState );


 const dolar = ( valor ) => {
  const action = {
    type: types.getDolar,
    payload:valor
  }
  dispatch( action )
 }

 const dolarComision = ( valor ) => {
  const action = {
    type: types.getDolarComision,
    payload:valor
  }
  dispatch( action )
 }

 const currency = ( valor ) => {
  const action = {
    type: types.setCurrency,
    payload:valor
  }
  dispatch( action )
 }



  return (
   <UiContext.Provider value={{
    UiState,

    //Methods
    dolar,
    dolarComision,
    currency
   }}>
      {children}
   </UiContext.Provider>
  )
}
