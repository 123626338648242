import React from "react";
import "./Carrousel.css";

import Carousel from "react-bootstrap/Carousel";
// import color from "../../../img/COLOR.jpg";
// import Negro from "../../../img/Negro.jpg";

import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import Social from "../Social/Social";
import { Twit } from "../Novedades/Twitter/Twitter";

const Carrousel = ({ title, list, listTw }) => {
  return (
    <div className="carrousel-contenedor-home">
      <h1>{title}</h1>
      <div className="carrousel-contenedor-only">
        <Carousel>
          {list
            ? list.map((item) => {
                return (
                  <Carousel.Item interval={6000}>
                    <img
                      className="img__carousel"
                      src={item.src}
                      alt="First slide"
                    />
                    {title === "Streamers" ? (
                      <a
                        className="redes-carrousel-each-twitch"
                        href={item?.twitch}
                        target="blank"
                      ></a>
                    ) : title === "Youtubers" ? (
                      <a
                        className="redes-carrousel-each-twitch"
                        href={item?.yt}
                        target="blank"
                      ></a>
                    ) : (
                      <a
                        className="redes-carrousel-each-twitch"
                        href={item?.tw}
                        target="blank"
                      ></a>
                    )}
                  </Carousel.Item>
                );
              })
            : listTw?.map((item) => {
                return (
                  <Carousel.Item interval={5000}>
                    <div className="contenedor-de-tweets">
                      <Twit twId={item?.id1} height={"300"} />
                      <Twit twId={item?.id2} height={"400"} />
                      <Twit twId={item?.id3} height={"300"} />
                    </div>
                  </Carousel.Item>
                );
              })}
        </Carousel>
      </div>

      {/* <Social /> */}

      {/* <ParallaxBannerComponent /> */}
    </div>
  );
};
export default Carrousel;

// export const c = () => {
//   return (
//     <div>
//       <ParallaxBanner
//         className="your-class"
//         layers={[
//           {
//             image: color,
//             amount: 0.1,
//           },
//           {
//             image: Negro,
//             amount: 0.5,
//           },
//         ]}
//         style={{
//           height: "480px",
//           width: "98vw",
//         }}
//       ></ParallaxBanner>
//     </div>
//   );
// };
