export const OrderBy = (order, inventario) =>{

    switch (order) {
      case "A":
        inventario.sort((x, y) =>{
          
          if (x.nombre < y.nombre) {return -1;}
          if (x.nombre > y.nombre) {return 1;}
          return 0;
      })
      break
      case "Z":
        inventario.sort((x, y) =>{
          
          if (x.nombre < y.nombre) {return 1;}
          if (x.nombre > y.nombre) {return -1;}
          return 0;
      })
      break
      case "Mayor":
        inventario.sort((x, y) =>{
          
          if (x.precio < y.precio) {return 1;}
          if (x.precio > y.precio) {return -1;}
          return 0;
      })
      break
      case "Menor":
        inventario.sort((x, y) =>{
                
          if (x.precio < y.precio) {return -1;}
          if (x.precio > y.precio) {return 1;}
          return 0;
      })
  
        break
      // case "":
      //   inventario.sort((x, y) =>{
                
      //     if (x.id < y.id) {return 1;}
      //     if (x.id > y.id) {return -1;}
      //     return 0;
      // })
      default:
        return inventario
    }
  
  }