import React from "react";

const PoliticasDePrivacidad = () => {
  return (
    <div style={{ padding: 30 }}>
      <h1>POLÍTICA DE PRIVACIDAD</h1>
      <p style={{ textAlign: 'justify' }}>
      La presente Política de Privacidad establece los términos en que Blazer Store 
      usa y protege la información que es proporcionada por sus usuarios al momento 
      de utilizar su sitio web. Esta compañía está comprometida con la seguridad de 
      los datos de sus usuarios. Cuando le pedimos llenar los campos de información 
      personal con la cual usted pueda ser identificado, lo hacemos asegurando que 
      sólo se empleará de acuerdo con los términos de este documento. Sin embargo 
      esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por 
      lo que le recomendamos y enfatizamos revisar continuamente esta página para 
      asegurarse que está de acuerdo con dichos cambios.
      </p>
      <h2>Uso de la información recogida</h2>
      <p style={{ textAlign: 'justify' }}>
        Nuestro sitio web emplea la información con el fin de proporcionar el mejor 
        servicio posible, particularmente para mantener un registro de usuarios.
        Nuestro sitio web podrá recoger información personal por ejemplo: Nombre, 
        información de contacto como su dirección de correo electrónica e información demográfica.
        Blazerstorecsgo está altamente comprometido para cumplir con el compromiso de mantener su 
        información segura. Usamos los sistemas más avanzados y los actualizamos constantemente 
        para asegurarnos que no exista ningún acceso no autorizado.
        Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio 
        con ofertas especiales, nuevos productos y otra información publicitaria que consideremos 
        relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán 
        enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
        <br/><br/>
        <a href="https://api.whatsapp.com/send?phone=+5492236785556&text=Hola.%20Quisiera%20solicitar%20el%20borrado%20de%20mi%20cuenta%20y%20mis%20datos%20en%20su%20sitio%20web">
          Solicitar el borrado de mi cuenta y todos los datos almacenados en este sitio.
        </a>
      </p>
    </div>
  );
};
export default PoliticasDePrivacidad;