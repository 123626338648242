import React from "react";
import {
  Instagram,
  Facebook,
  Twitter,
  Google,
  Whatsapp,
} from "react-bootstrap-icons";
import "./Social.css";

export default function Social({ navBar, colorNegro, noDispose }) {
  return (
    <div
      className={
        noDispose
          ? "contenedor-socials-noflex-none"
          : "justify-content-center contenedor-socials"
      }
    >
      <ul className={colorNegro ? "ul color-ngro" : "ul"}>
        <li className="horizontal">
          <a
            href="https://www.facebook.com/blazerstorecsgo/"
            target="blank"
            className={navBar ? "text-white-nav" : "text-white"}
          >
            <Facebook className={navBar ? "icon__social" : ""} />
          </a>
        </li>
        <li className="horizontal">
          <a
            href="https://twitter.com/blazerstorecsgo"
            target="blank"
            className={navBar ? "text-white-nav" : "text-white"}
          >
            <Twitter className={navBar ? "icon__social" : ""} />
          </a>
        </li>
        <li className="horizontal">
          <a
            href="https://www.instagram.com/blazerstorecsgo/"
            target="blank"
            className={navBar ? "text-white-nav" : "text-white"}
          >
            <Instagram className={navBar ? "icon__social" : ""} />
          </a>
        </li>
        <li className="horizontal">
          <a
            href="https://api.whatsapp.com/message/JPG7W4I2LY4YJ1"
            target="blank"
            className={navBar ? "text-white-nav" : "text-white"}
          >
            <Whatsapp className={navBar ? "icon__social" : ""} />
          </a>
        </li>
        {/*
        https://api.whatsapp.com/message/JPG7W4I2LY4YJ1
          <li className="horizontal">
          <a href="pagos@mdqservers.net" class="text-white">
            <Google />
          </a>
        </li>
        */}
      </ul>
    </div>
  );
}
