import { Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import { UiContext } from "../../../Contexto/ui/UiContext";
import "./RangoPrecios.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    padding: "0 30px",
  },
  slider: {
    padding: "0px 0px",
  },
}));

const RangoPrecio = ({ precioComparador, setPrecioComparador }) => {

  const { UiState, currency } = useContext( UiContext )
  const { currency: currentCurrency } = UiState

  const classes = useStyles();

  function handleChange(event, value) {
    setPrecioComparador(value);
    
  }

  function handleChangeMoving(event, value) {
    setPrecioComparador(value);
  }

  return (
    <div className={classes.root}>
      <div className="contenedor-silder-titulo">
        <Slider
          classes={classes.slider}
          value={precioComparador}
          aria-labelledby="label"
          onChange={(event, value) => handleChangeMoving(event, value)}
          onChangeCommitted={(event, value) => handleChange(event, value)}
          min={0}
          max={currentCurrency === 'ARS' ? 650000 : 1500}
          valueLabelDisplay="on"
          enterTouchDelay={0}
          color="colorSecondary"
        />
      </div>

      <h1 className="titulo-slieder">Rango de precios</h1>
    </div>
  );
};
export default RangoPrecio;
