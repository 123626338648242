import { types } from "../types";


export default  (state,action)=>{


switch (action.type) {

    case types.setProductsCart:
        return {
            ...state,
            productsInCart: [
                ...state.productsInCart,
                action.payload
            ]
       
           
        }
    case types.removeProductCart:
        return {
            ...state,
            productsInCart: state.productsInCart.filter( prod => prod.id !== action.payload.id )
                 
        }

    case types.clearCart:
        return {
            productsInCart:[]
        }


    default:
        break;
}
}