import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./CardMaterialUi.css";
import {
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Card,
  Button,
  Box,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Path from "../../../Constantes/Constantes";
import "./CardMaterialUi.css";
import { CalcularFecha } from "../../Funciones/CalcularFechas";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CarritoContext from "../../../Contexto/Carrito/CarritoContext";
import DeleteIcon from "@material-ui/icons/Delete";
import parse from 'html-react-parser';
import { editarArticulo } from "../../../Services/EditarEstadoItem";
import { UiContext } from "../../../Contexto/ui/UiContext";
import steamLogo from "../../../img/steam-logo.png";

var scale = "scale(1.3)";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 195,
    margin: 10,
    // padding: 5,
    background: "#ffffffbd",
    fontFamily: "Roboto Mono monospace",
    fontWeight: "700 !important",
    // textTransform: "uppercase",
    color: "#212121",
    textShadow: " 1px 1px #01ec9629",
    "&:hover": {
      boxShadow: "0px 6px 20px 0px #ff0042",
    },
  },

  descripcion: {
    height: 100,
    overflowY: "scroll",
  },
  media: {
    // height: 0,
    width: '100%',
    paddingTop: "56.25%", // 16:9
    margin: '10px 0',
    // padding: '40px',
    backgroundColor:
      "linear-gradient(180deg, rgba(0,0,0,0.4598214285714286) 79%, rgba(255,0,0,0.4234068627450981) 91%)",
    "&:hover": {
      transform: (scale = "scale(1.3)"),
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  headerOnly: {
    fontSize: "25px !important",
    maxHeight: "75px",
    minHeight: "75px",
  },
  headerOnlyLitle: {
    maxHeight: "75px",
    minHeight: "75px",
    fontSize: "15px !important",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  colorVerde: {
    color: "#01ec96",
    "&:hover": {
      color: "#212121",
      backgroundColor: "#01ec96",
    },
  },
  colorRosa: {
    color: "#ff0042",
    fontWeight: "600 !important",
    "&:hover": {
      color: "#212121",
      backgroundColor: "#ff0042",
    },
  },
  eliminarButon: {
    maxWidth: '10px',
    minWidth: '34px',
    fontWeight: "100 !important",
    "&:hover": {
      // padding: '0 0' ,
 
      color: "#212121",
      fontSize: '1.3rem',
      // color: "#ff0042",
      backgroundColor: "#ff0042",
    },
  },
  estado: {
    color: "#212121",
    fontWeight: "bold",
    maxHeight: "15px",
    minHeight: "15px",
  },
}));

export default function RecipeReviewCard({
  handleInputAddItem,
  itemUnico,
  usuarioData
}) {

  const [diasFaltantes, setDiasFaltantes] = useState("");
  const { CartState } = useContext(CarritoContext);
  const { productsInCart } =CartState
  const { UiState } = useContext( UiContext )

  const { dolar, currency, dolarComision } = UiState

  // const found = carrito.find((element) => element.id === itemUnico.id);
  const found = productsInCart.find((element) => element.id === itemUnico.id);



if (itemUnico.descripcion[6] ){
  if(itemUnico.descripcion[6].value){
    // console.log((itemUnico.descripcion[6].value).includes('Pegatina'))

     if ( (itemUnico.descripcion[6].value).includes('Pegatina')){
      itemUnico.sticker={
            icon:((itemUnico.descripcion[6].value).split("<br>")[1]).split('<center>')[1],
            name: (itemUnico.descripcion[6].value).split("<br>")[2].split('<')[0],
          }
        }else {
          itemUnico.sticker = null
        }


  }
  }

// console.log(itemUnico.tipo)
if ( itemUnico.tipo === 'Ametralladora'){
  console.log(itemUnico)
}

  useEffect(() => {

    if (
      itemUnico.cacheExpiration !== "" &&
      itemUnico.cacheExpiration !== undefined
    ) {
      var hoy = new Date();
      var expiration = new Date(itemUnico.cacheExpiration);
      if (hoy < expiration) {
        var diff = CalcularFecha(hoy, expiration);
        setDiasFaltantes(diff);
      } else {
        setDiasFaltantes("");
      }
    }
  }, []);

  var item = {
    nombre: "nahuel",
    foto: "unafoto",
    descripcion: { 2: { type: "", value: "" } },
  };
  item = itemUnico;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function handleEliminarDeTienda(id) {
    if (usuarioData.tipo === "Administrador") {
      await editarArticulo(id, false).then((res) => {
        if (res) {
        } else {
        }
        return res;
      });
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        // avatar={
        //   <Avatar
        //     aria-label="recipe"
        //     src={logo}
        //     className={classes.avatar}
        //   ></Avatar>
        // }
        className={
          item.nombre.length > 25 ? classes.headerOnlyLitle : classes.headerOnly
        }
     
        title={<Typography style={{ fontWeight:'700', fontSize: '0.8rem'}} >{item.nombre} </Typography>}
        
      />

      <CardMedia
        className={classes.media}
        image={Path.IMAGEN_URL + item.foto}
        title="Paella dish"
      />

      {itemUnico.sticker&&
       

            <div className='sticker-container'> 
            <div className='sticker-info'>
            { parse(`${itemUnico.sticker.name}`) }
            </div>

            <div  className="sticker-icon">
                <div className='sticker'>

                { parse(`${itemUnico.sticker.icon}`) }
                </div>
            </div>
            </div>
        

          }
      {diasFaltantes !== "" && diasFaltantes != 0 ? (
        <Typography className="item-disponible" style={{ fontWeight:'700', fontSize: '0.8rem'}}>
          En {diasFaltantes} {diasFaltantes == 1 ? "dia" : "dias"}
        </Typography>
      ) : (
        <Typography className="item-disponible-true" style={{ fontWeight:'700', fontSize: '0.8rem'}}>Entrega Inmediata</Typography>
      )}
      <CardContent>
        <Typography variant="body2" className={classes.estado} component="p">
          Float:{" "}
          {typeof itemUnico.floatValue === "string"
            ? "-"
            : itemUnico?.floatValue?.toFixed(4)}
        </Typography>
        {/* <Typography
          className={classes.descripcion}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {item.descripcion[2] ? item.descripcion[2].value : "No description"}
        </Typography> */}




      </CardContent>
      <CardActions >
        <div
          size="small"
          className={classes.colorVerde}
          onClick={() => handleInputAddItem(itemUnico)}
          style={{
            border: '1px solid green',
            padding: '1px 2px',
            borderRadius: '10%',
            cursor:'pointer'
          }}
        >
          {found ? (
            <ShoppingCartIcon fontSize="small" />
          ) : (
            <AddShoppingCartIcon fontSize="small" />
          )}
        </div>
        <div>

          <a
            size="small"
            // className={classes.colorRosa}
            className="inspect"
            href={itemUnico.actionsLink}
            style={{
              cursor:'pointer'
            }}
          >
          <img src={steamLogo} alt="" />
          </a>
        </div>

      </CardActions>
      <div className="item-precio " style={{
        position: 'relative',
        display: 'flex',
        justifyContent:'center',
        // marginLeft: '30px'
      }}
      >
        <div >
          {
            currency === 'ARS' &&
            <span>${Math.round(itemUnico.precio * dolar) }</span>
          }
          {
            currency === 'USD' &&
            <span>${(itemUnico.precio * dolarComision).toFixed(2) }</span>
          }

        </div>
        {usuarioData.tipo !== "Administrador" ? null : (
          <Button
            size="small"
            className={classes.eliminarButon}
            style={{
              position: 'absolute',
              right: '10px',
     
              // marginLeft: '20px',
              // marginRight: '-30px'
            }}
            onClick={() => handleEliminarDeTienda(itemUnico.id)}
          >
            <DeleteIcon />
          </Button>
        )}
      </div>
    </Card>
  );
}
