import React, { useState } from "react";
import "./Administrador.css";
// import getInventario from "../../Services/GetInventory";
import getInventario, { getNewInve } from "../../Services/GetInventorycopy";
import Galeria from "../Tienda/Galeria/Galeria";
import { TituloPaginaV3 } from "../../components/TitulosHeader/TituloPagina";
import PanelAdminSkins from "./PanelAdmin/PanelAdminSkins";
import { Form, Button, Alert } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import { useForm } from "../../hooks/useForm";
import { useContext } from "react";
import { UiContext } from "../../Contexto/ui/UiContext";
import { db } from "../../firebase";
import { useEffect } from "react";

const Administrador = ({ data }) => {
  const [redirect, setRedirect] = useState(!data.userAuth);
  const [inventary, setInventary] = useState({});
  const [loading, setLoading] = useState(true);
  const [alerta, setAlerta] = useState("");
  const [userInv, setUserInv] = useState("");
  const [id64Inv, setId64Inv] = useState("");

  const { UiState, dolar:setDolar , dolarComision: setDolarComision } = useContext( UiContext )

  const { dolar: dolarState , dolarComision: dolarComisionState } = UiState

  const [inv, setInv] = useState([]);

  const formData ={
    dolarValue: dolarState,
    dolarComisionValue: dolarComisionState
  }

  const { formState, dolarValue, dolarComisionValue , onInputChange} = useForm( formData );

  useEffect(() => {
    const inveInLocalStorage = JSON.parse(localStorage.getItem('inveSteam'))
    if( inveInLocalStorage ){
      // console.log(inveInLocalStorage)
      setInventary(inveInLocalStorage);
      setLoading(false)
    }
  }, [])
  

  async function traerInv(e) {
    e.preventDefault();
    setLoading(true);
    //  keyword: "emmik", id64: "76561198877233929"
    
    if (userInv && id64Inv) {
      await getNewInve({ keyword: userInv, id64: id64Inv }).then(
        // r=>{
        //   console.log('b')
        //   console.log('ASDASD',r)
        // }
       resp => {
          if (resp.success) {
            setInventary(resp.arrayInventario);
            setLoading(false)
          } else {
            setAlerta("Los datos ingresados no son correctos");
          }
        }
      );
    } else {
      setAlerta("Ingrese los datos");
    }
  }

  if (!data.userAuth || data.usuarioData.tipo != "Administrador") {
    setRedirect(true);
  }

  const submitDolar = async(e) => {
    e.preventDefault()

    await db.collection("dolar").doc('1').set({valor: dolarValue})
    setDolar(dolarValue)

  }
  const submitDolarComision = async(e) => {
    e.preventDefault()

    await db.collection("dolarComision").doc('1').set({value: dolarComisionValue});
    setDolarComision( dolarComisionValue )
  }

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="contenedor-admin-max">
      <TituloPaginaV3 titulo="Administrador" />
      <div className="formulario-admin-datos">
        <Form
          inline
          onSubmit={(e) => traerInv(e)}
          className="formulario-contenedor-admin-datos"
        >
          <Form.Control
            className="mr-sm-2"
            type="text"
            placeholder="User"
            onChange={(e) => setUserInv(e.target.value)}
          />
          <Form.Control
            className="mr-sm-2"
            type="text"
            placeholder="id64"
            onChange={(e) => setId64Inv(e.target.value)}
          />
          <Button type="submit" variant="outline-primary">
            Buscar
          </Button>
        </Form>
        <div className="formulario-admin-datos" style={{ width: '250px'}}>

            <Form
            className="formulario-contenedor-admin-datos"
            onSubmit={ submitDolar }
          >
            <Form.Control
              className="mr-sm-2"
              type="number"
              placeholder={dolarState}
              name="dolarValue"
              value={ dolarValue }
              onChange={ onInputChange }
            />
            <Button
             type="submit"
              variant="outline-primary">
              Aplicar
            </Button>
          </Form>

        </div>

        <div className="formulario-admin-datos" style={{ width: '250px'}}>

            <Form
            className="formulario-contenedor-admin-datos"
            onSubmit={ submitDolarComision }
          >
            <Form.Control
              className="mr-sm-2"
              type="number"
              placeholder={dolarState}
              name="dolarComisionValue"
              value={ dolarComisionValue }
              onChange={ onInputChange }
            />
            <Button
             type="submit"
              variant="outline-primary">
              Aplicar
            </Button>
          </Form>

        </div>

        {alerta !== "" ? (
          <Alert style={{ marginTop: 10 }} variant="danger">
            {alerta}
          </Alert>
        ) : null}
      </div>

      {loading ? (
        <div>
          <h1>esperando</h1>
        </div>
      ) : (
        <div>
          {/* <Galeria fuente="Administrador" items={inventary} /> */}
          <PanelAdminSkins items={inventary} />
        </div>
      )}
    </div>

  );
};
export default Administrador;
