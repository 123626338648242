import { db } from "../firebase";

export async function GetValorDolar() {

  let dolar;

  try {
    
    const res = await db
      .collection("dolar")
      .limit(1)
      .get();
  
       dolar = res.docs[0].data()['valor'];
      
  } catch (error) {
    dolar = 325
  }
  return dolar;
}

export async function GetValorDolarComision() {

  let dolarComision;

  try {
    const res = await db
      .collection("dolarComision")
      .limit(1)
      .get();
      
      const {value} = res.docs[0].data();

    dolarComision = value
  } catch (error) {
    dolarComision = 1.05
  }
  
  return dolarComision;
}