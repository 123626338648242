import React, { useState, useEffect, useContext } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Form,
  FormControl,
  Modal,
} from "react-bootstrap";
import { Link, Redirect, useLocation } from "react-router-dom";
import icon from "../../img/Logo/icon.png";
import letras from "../../img/Logo/letras.png";
import "./NavBarComponent.css";
import { RandomReveal } from "react-random-reveal";
import { auth, db } from "../../firebase";
import Login from "../Login/Login";
import Drawer from "../Drawer/Drawer";
import Avatar from "react-avatar";
import { Badge } from "@material-ui/core";
import CarritoContext from "../../Contexto/Carrito/CarritoContext";
import Social from "../Home/Social/Social";
import { CartIcon } from "../../components/cart/CartIcon";

const NavBarComponent = ({ data }) => {
  const location = useLocation();
  const {showDrawer, setShowDrawer} = data
  const { CartState } =  useContext(CarritoContext);
  const { productsInCart } = CartState

  
  const [showLogin, setShowLogin] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [logeado, setLogeado] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLogeado(true);
      } else {
        setLogeado(false);
      }
    });
  }, []);

  // ==== CERRAR SESIÓN ====
  let cerrarSesion = () => {
    auth
      .signOut()
      .then(() => {
        setLogeado(false);
        setRedirect(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div id="InicioRef"></div>

      <Drawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        setShowLogin={setShowLogin}
        logeado={logeado}
        cerrarSesion={cerrarSesion}
      />
      <Navbar variant="dark" className="color-navbar justify-content-between">
        <Navbar.Brand>
          <Link to="/">
            <div className="logo-izquierda">
              <img
                src={icon}
                width="80"
                height="80"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
              <img
                src={letras}
                width="80"
                height="80"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </div>
          </Link>
        </Navbar.Brand>
        <Nav className="mrauto">
          <Link to="/">
            <Nav.Link href="/" className="letraBlanca">
              Inicio
            </Nav.Link>
          </Link>

          {/* {data.userAuth ? ( */}
            <Link to="/Tienda">
              <Nav.Link href="/Tienda" className="letraBlanca">
                Tienda
              </Nav.Link>
            </Link>
          {/* ) : (
            <Nav.Link
              // href="/Tienda"
              onClick={() => setShowDrawer(true)}
              className="letraBlanca"
            >
              Tienda
            </Nav.Link>
          )} */}

          {data.usuarioData.tipo === "Administrador" ? (
            <Link to="/Administrador">
              <Nav.Link href="/Administrador" className="letraBlanca">
                Admin
              </Nav.Link>
            </Link>
          ) : null}

          <Link to="/VenderSkins">
            <Nav.Link href="/VenderSkins" className="letraBlanca">
              Vender tus skins
            </Nav.Link>
          </Link>

          {location.pathname === "/" ? (
            <Nav.Link href="/#SponsorId" className="letraBlanca">
              Sponsors
            </Nav.Link>
          ) : (
            <Link to="/Sponsors">
              <Nav.Link href="/Sponsors" className="letraBlanca">
                Sponsors
              </Nav.Link>
            </Link>
          )}

          <Link to="/PreguntasFrecuentes">
            <Nav.Link href="/PreguntasFrecuentes" className="letraBlanca">
              Preguntas frecuentes
            </Nav.Link>
          </Link>
        </Nav>

        <Social navBar={true} />
        {/* <Form inline>
          <FormControl type="text" placeholder="Search" className="mr-sm-2" />
          <Button variant="outline-primary">Search</Button>
        </Form> */}

   
          {productsInCart[0] &&
            <div style={{ marginRight: '-110px' }}>

              <Link className="link-drawer" to="/Pagar">
                  <CartIcon 
                    productsInCart={productsInCart} 
                    color={'red'}
                  />
              </Link>
            </div>
            
          }

        {!logeado ? (

            <div
              className="boton-login-navbar"
              onClick={() => setShowDrawer(true)}
            >
              
              <h1>Login</h1>
            </div>
     
        ) : (
          <div  style={{display: 'flex', alignItems:'center'}}>

            <div
              className="boton-login-navbar"         
              onClick={() => setShowDrawer(true)}
            >

              <div className="hover-foto">
                {productsInCart.length > 0 ? (
                  // <Badge
                  //   badgeContent={productsInCart.length}
                  //   color="secondary"
                  //   anchorOrigin={{
                  //     vertical: "bottom",
                  //     horizontal: "left",
                  //   }}
                  // >
                  <>
                    {data.usuarioData.foto ? (
                      <Avatar
                        src={data.usuarioData.foto}
                        size="50"
                        round={true}
                      />
                    ) : (
                      <Avatar
                        name={data.usuarioData.nombre}
                        size="50"
                        round={true}
                      />
                    )}
                  </>
                  // </Badge>
                ) : data.usuarioData.foto ? (
                  <Avatar src={data.usuarioData.foto} size="50" round={true} />
                ) : (
                  <Avatar name={data.usuarioData.nombre} size="50" round={true} />
                )}
              </div>
              
            </div>
            
          </div>
        )}
      </Navbar>

      <Modal
        size="xl"
        show={showLogin}
        style={{ background: "Black" }}
        centered
        fullscreen={true}
      >
        <Modal.Body style={{ background: "Black" }}>
          <Login
            setShowLogin={setShowLogin}
            setUsuarioData={data.setUsuarioData}
            setIsDataLoading={data.setIsDataLoading}
          />
        </Modal.Body>
        <Modal.Footer style={{ background: "Black" }}>
          <Button onClick={() => setShowLogin(false)}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NavBarComponent;
