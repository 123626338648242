import React from "react";
import "./CardItem.css";
import { Card, Button } from "react-bootstrap";
import Path from "../../Constantes/Constantes";

const CardItem = ({ itemUnico }) => {
  var item = { nombre: "nahuel", foto: "unafoto" };
  item = itemUnico;
  return (
    <Card>
      <Card.Img
        variant="top"
        className="imgCard"
        src={Path.IMAGEN_URL + item.foto}
      />
      <Card.Body>
        <Card.Title>{item.nombre}</Card.Title>
        <Card.Text>
          {item.descripcion[2] ? item.descripcion[2].value : "No description"}
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">
          Agregaras este producto a la tienda
        </small>
      </Card.Footer>
    </Card>
  );
};

export default CardItem;
