
import { useEffect, useState } from 'react';

import { auth, db } from '../../firebase';
import { useForm } from '../../hooks/useForm';
import AlertError from '../Login/Alerta/AlertError';

import './register.css'

const formData = {
    email: '',
    password: '',
    nombre: '',
    nacionalidad: '',
    telefono: '',
    foto: '',
    cuentaCreada: new Date(),
    tipo: "Usuario",
  }

const formValidations = {
    email: [ (value) => value.includes('@'), 'El correo debe ser un formato válido.'],
    password: [ (value) => value.length >= 6, 'El password debe de tener al menos 6 caracteres.'],
    nombre: [ (value) => value.length >= 1, 'El nombre es obligatorio.'],
  }


export const Register = ({ setShowLogin, setUsuarioData }) => {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [error, setError] = useState("");


    const { 
        formState, nombre, email, password, onInputChange,
        isFormValid, nombreValid, emailValid, passwordValid, 
      } = useForm( formData, formValidations );

      const onSubmit = ( event ) => {
        event.preventDefault();
        
        if ( !isFormValid ) return setErrorMessage(true) ;
         auth.createUserWithEmailAndPassword( email, password )
                    .then((resp) => {
                     
                    
                     setUser(resp).then( user => setUsuarioData( user ) )
                     
                      
                     
                    })
                    .then( resp => {
                      if(!error){
                        setShowLogin(false)
                      }
                    })    
                    .catch(e=> {
                     
                        console.log(e)
                        setearCaseError(e.code)
                    })
 
                
                 
      }


      const setUser = async ({user}) => {
   
        const result = await db
          .collection("users")
          .where("email", "==", user.email)
          .get();
        console.log(result)
        if (result.docs.length === 0) {
          // Set new data since this is a new user
          //Hay que permitir para poder acceder a la location con user_location , de momento esta fijado Argentina
          const usuario = {
            id: user.uid,
            nombre: nombre,
            email: user.email,
            // nacionalidad: nacionalidad.city + " , " + nacionalidad.country,
            telefono: '',
            foto: '',
            cuentaCreada: new Date(),
            tipo: "Usuario",
          };
          await db.collection("users").doc(user.uid).set(usuario);
          return usuario
        }

      }

      useEffect(() => {

        setError('')
        
      }, [nombreValid,emailValid, passwordValid, error])

      const setearCaseError = (e) => {
        switch (e) {
          case "auth/email-already-in-use":
            setError("Email en uso");
            break;
          case "auth/user-not-found":
            setError("Email no encontrado u erroneo");
            break;
          case "auth/invalid-email":
            setError("Email no encontrado u erroneo");
            break;
          case "auth/too-many-requests":
            setError("Intentos maximos superados, intente loguear en unos minutos");
            break;
          default:
            break;
        }
      };
    
      const ErrorData = {
        icon: "error",
        title: "Oops..",
        type: "error",
        text: error.toString(),
        footer: "<a href>Why do I have this issue?</a>",
      };
      

  return (
    <form onSubmit={ onSubmit }>
       
    <div container className='registser'>
     
      <div item xs={ 12 }  className="input-container">
        <input 
          type="text" 
          placeholder='Nombre completo' 
          name="nombre"
          value={ nombre }
          onChange={ onInputChange }
          error
        />
        {errorMessage &&
        
            <div className="error-messaje">{nombreValid}</div>
        }
      </div>

      <div item xs={ 12 } className="input-container">
        <input 
          type="email" 
          placeholder='correo@google.com' 
          autoComplete='off'
          name="email"
          value={ email }
          onChange={ onInputChange }
        />
        {errorMessage &&
        
            <div className="error-messaje">{emailValid}</div>
         }
      </div>

      <div item xs={ 12 }  className="input-container">
        <input 
          type="password" 
          placeholder='Contraseña' 
          name="password"
          value={ password }
          onChange={ onInputChange }
        />
        {errorMessage &&
        
            <div className="error-messaje"><span>{passwordValid}</span></div>
        }
      </div>
      {error !== "" ? <AlertError {...ErrorData} /> : <div></div>}
             <div    style={{ textAlign: 'center', margin: '15px 0' }}>

                <button 
                    // disabled={ isCheckingAuthentication }
                
                    className='btn-primary-'
                    type="submit"
                    variant='contained' 
                    fullWidth>
                    Crear cuenta
                </button>
             </div>
             

    </div>
   

  </form>
  )
}
