import { db } from "../firebase";

export async function BuscarProducto(id) {
  const snapshot = await db
    .collection("tienda")
    .where("id", "==", id)
    .where("disponible", "==", true)
    .get();
  if (snapshot.docs.length > 0) {
    return snapshot.docs.map((doc) => {
      if (doc.data().disponible) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    return false;
  }
}

export async function AgregarProducto(producto) {
  // console.log(producto);
  var idProducto = producto.item.classid + "_" + producto.item.instanceid;
  return await BuscarProducto(idProducto).then((res) => {
    var fechaHoy = new Date().toDateString();
    if (!res) {
      return db
        .collection("tienda")
        .doc(idProducto)
        .set({
          fechaSubido: fechaHoy,
          floatValue: producto.item.floatValue,
          cacheExpiration: producto.item.cacheExpiration,
          actionsLink: producto.item.actionsLink,
          tipo: producto.item.tipo,
          id: idProducto,
          nombre: producto.item.nombre,
          foto: producto.item.foto ? producto.item.foto : "",
          descripcion: producto.item.descripcion,
          classid: producto.item.classid,
          instanceid: producto.item.instanceid,
          disponible: true,
          precio: parseFloat(producto.precio[0]),
        })
        .then(() => {
          return true;
        })
        .catch((error) => {
          return error;
        });
    } else {
      return false;
    }
  });
}
