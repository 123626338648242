import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { ParallaxProvider } from 'react-scroll-parallax';
import { UiProvider } from "./Contexto/ui/UiProvider";
import { SnackbarProvider } from 'notistack';
import { MySuccessSnackbar } from './components/cart/MySuccessSnackbar';


ReactDOM.render(
  <React.StrictMode>
    <ParallaxProvider> 
      <SnackbarProvider maxSnack={3}   >
        <UiProvider>

            <App />
          
        </UiProvider>
      </SnackbarProvider>
    </ParallaxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
