import React, { useState, useContext } from "react";

import CarritoContext from "../../Contexto/Carrito/CarritoContext";
import Button from "react-bootstrap/Button";
import "./Pagar.css";
import Path from "../../Constantes/Constantes";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { GetCupon } from "../../Services/GetCupones";
import { CalcularFecha } from "../../components/Funciones/CalcularFechas";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FinalizarCompra from "./FinalizarCompra/FinalizarCompra";
import { UiContext } from "../../Contexto/ui/UiContext";
const Pagar = ({ data  }) => {
  const {setShowDrawer} = data
  const { CartState, removeProductCart, clearCart } = useContext(CarritoContext);
  const { productsInCart } = CartState 
  const { UiState } = useContext( UiContext )
  const { dolar, currency, dolarComision} = UiState

  const totalPrice = productsInCart.map( prod => prod.precio)
  const precioFinal = totalPrice.reduce((a, b) => a + b, 0);


  const [show, setShow] = useState(false);
  const [showFinalizar, setShowFinalizar] = useState(false);
  const [cupon, setCupon] = useState("");
  const [precioConPromocion, setPrecioConPromocion] = useState(0);
  const [errorCupon, setErrorCupon] = useState("");
  const [validarFin, setValidarFin] = useState(true);

  const removeItemInCart = (item) => {
    removeProductCart(item)
  }

  const handleClearCart = () => {
    clearCart()
  }

  async function valdiarCupon() {
    setValidarFin(false);
    setErrorCupon("");
    if (cupon === "") {
      setErrorCupon("Debe ingresar un cupon valido");
    } else {
      await GetCupon(cupon).then((res) => {
        if (res.length > 0) {
          setPrecioConPromocion(res[0].porcentaje);
        } else {
          setErrorCupon("Este cupon no existe, vuelva a intentar");
        }
        handleClose();
        setValidarFin(true);
      });
    }
  }
  function handleClose() {
    setShow(false);
  }

  function handleCloseFinalizar() {
    setShowFinalizar(false);
  }

  function dowloadPdf(toPdf) {
    toPdf();
  }

  // => Redirección
  if (productsInCart.length === 0) {
    window.location.href = Path.SITE_URL;
  }


  return (
    <>
      <div className="contenedor-pagar-abuelo">
        <div className="contenedor-pagar-padre">
          <div className="contenedor-izquierda">
            <div style={{ display: 'flex', justifyContent: 'center', gap:'13px'}}>

              <h1>Mi Compra</h1>

            </div>
            {productsInCart?.map((item) => {
              return (
                <div className="card-item-pagar">
                  <img src={Path.IMAGEN_URL + item.foto} alt="" />
                  <div className="contenedor-data-item">
                    <h1>{item?.nombre}</h1>
                    {
                      currency === 'ARS' &&

                      <h2>${(item?.precio * dolar).toFixed(2)}</h2>
                    }
                    {
                      currency === 'USD' &&

                      <h2>${(item?.precio *dolarComision).toFixed(2)}</h2>
                    }
                    {new Date(item.cacheExpiration) > new Date() ? (
                      <h3>
                        Disponible el:{"  "}
                        {new Date(item?.cacheExpiration).toLocaleDateString()}
                      </h3>
                    ) : (
                      <h4>Disponible</h4>
                    )}
                  </div>
                  <DeleteForeverIcon
                    className="boton-eliminar-carrito mt-3"
                    onClick={() => removeItemInCart(item)}
                  />
                </div>
              );
            })}
          </div>

          <div className="contenedor-derecha" style={{ display: 'flex', flexDirection:'column', justifyContent: 'space-between', height: '70vh' }}>
            <div>
              <h1>Resumen del Pedido</h1>
              <div className="contenedor-subtotal">
                <h1>SubTotal</h1>
                <h2>
                  ${
                    currency === 'ARS' &&
                    <>
                      {precioConPromocion != 0
                        ?( (precioFinal - (precioFinal * precioConPromocion) / 100) * dolar).toFixed(2)
                        : (precioFinal * dolar).toFixed(2)}
                    
                    </>
                  }
                  {
                    currency === 'USD' &&
                    <>
                      {precioConPromocion != 0
                        ? (precioFinal - (precioFinal * precioConPromocion) / 100) * dolarComision
                        : (precioFinal * dolarComision).toFixed(2)}
                    
                    </>
                  }
                </h2>
              </div>
              {precioConPromocion != 0 ? (
                <div className="contenedor-descuento">
                  <h1>Descuento</h1>
                  {
                    currency === 'ARS' &&
                    <h2>-${ (precioFinal * dolar) * precioConPromocion / 100}</h2>
                  }
                  {
                    currency === 'USD' &&
                    <h2>-${(precioFinal * dolarComision) * precioConPromocion / 100 }</h2>
                  }
                </div>
              ) : null}

              <>
                { data.userAuth ? (
                  <Button
                  variant="dark"
                  style={{ width: "100%" }}
                  disabled={!validarFin}
                  // onClick={() =>
                  //   console.log(
                  //     carrito,
                  //     precioTotal,
                  //     precioConPromocion,
                  //     "Desc Pre:" + (precioTotal * precioConPromocion) / 100,
                  //     "Precio final:" + precioConPromocion != 0
                  //       ? precioTotal - (precioTotal * precioConPromocion) / 100
                  //       : precioTotal
                  //   )
                  // }
                  onClick={() => setShowFinalizar(true)}
                >
                  Finalizar Compra
                </Button>

                ) : (

                  <Button
                  variant="dark"
                  style={{ width: "100%" }}
                  disabled={!validarFin}
                  // onClick={() =>
                  //   console.log(
                  //     carrito,
                  //     precioTotal,
                  //     precioConPromocion,
                  //     "Desc Pre:" + (precioTotal * precioConPromocion) / 100,
                  //     "Precio final:" + precioConPromocion != 0
                  //       ? precioTotal - (precioTotal * precioConPromocion) / 100
                  //       : precioTotal
                  //   )
                  // }
                  onClick={() => setShowDrawer(true)}
                >
                  Finalizar Compra
                </Button>
                )
              
              
                }


              </>


              <div className="contenedor-cupon">
                <Button
                  size="sm"
                  variant={precioConPromocion != 0 ? "success" : "dark"}
                  onClick={() => setShow(true)}
                >
                  <LocalMallIcon />
                  <h2>Ingresar Cupon</h2>
                </Button>
              </div>
              {errorCupon !== "" ? (
                <Alert variant="success">{errorCupon}</Alert>
              ) : null}

            </div>

            <Button
               
              onClick={handleClearCart}
            >
              Vaciar carrito
              
            </Button>
          </div>

        </div>
        
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Valida tu cupon</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Label>Cupon de descuento</Form.Label>
          <Form.Control
            type="text"
            name="Cupon de descuento"
            onChange={(e) => setCupon(e.target.value)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={() => valdiarCupon()}>
            Validar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showFinalizar} onHide={handleCloseFinalizar}>
        <Modal.Header closeButton>
          <Modal.Title>Ultimos pasos!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="contenedor-finalizar-detalle">
            <FinalizarCompra
              precioTotal={precioFinal}
              precioConPromocion={precioConPromocion}
              carrito={productsInCart}
              data={data}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Pagar;
