import React from "react";
import "./VenderSkins.css";
import Contacto from "../../components/Contacto/Contacto";
import ejLogo from "../../img/ejLogo.png";
import pagosLogo from "../../img/pagosLogo.png";
const VenderSkins = () => {
  return (
    <div className="background-img-vender-skins">
      <div className="contenedor-padre-vender-skins">
        <h1>¿Como vender tus skins o inventario?</h1>
        <p>
          Es más facil de lo que pensás, comunicate a cualquiera de nuestras
          redes sociales y envianos el link de tu{" "}
          <a
            href="https://steamcommunity.com/login/home/?l=spanish"
            target="blank"
          >
            perfil de Steam
          </a>
          .
        </p>
        <Contacto />

        <p>
          También podés incluir una captura de tu inventario en{" "}
          <a href="https://cs.money/es/" target="blank">
            cs.money
          </a>
          , ó de las skins que quieras vender. Captura de ejemplo
        </p>

        <img className="achicar-img" src={ejLogo} alt="" />

        <p>
          Te contestaremos lo antes posible ofreciendote el mejor precio por tus
          skins o inventario. En caso de recibir una mejor oferta, avisanos y
          veremos si la podemos mejorar!
        </p>

        <p>Nuestros métodos de pago:</p>
        <img className="pagos-logos" src={pagosLogo} alt="" />
      </div>
    </div>
  );
};

export default VenderSkins;
