import React from "react";
import { Timeline, Tweet } from "react-twitter-widgets";
import "./Twitter.css";
//npm install --save react-twitter-widgets --force
export const Twitter = () => {
  return (
    <div>
      <div>
        <div className="Contenedor-tweet">
          <Timeline
            dataSource={{
              sourceType: "profile",
              screenName: "blazerstorecsgo",
            }}
            options={{
              color: "#fff",
              height: "600",
              width: "500",
              borderColor: "#fff",
              chrome: "transparent, noheader, nofooter, noscrollbar",
              widget_type: "grid",
            }}
            renderError={(_err) => (
              <div>
                {" "}
                UPS! Ha ocurrido un error, por favor recargue la página
              </div>
            )}
          />
        </div>
      </div>

      {/*<Follow username={"MDQServers"} options={{ size: "large" }} />*/}
    </div>
  );
};

export const Twit = ({ twId, height }) => {
  return (
    <div className="tarjeta">
      <Tweet
        tweetId={twId}
        options={{
          height: "600",
          width: "600",
          borderColor: "#fff",
          chrome: "transparent, noheader, nofooter, noscrollbar",
          widget_type: "grid",
          theme: "dark",
        }}
      />
    </div>
  );
};
