import {useReducer} from 'react'
import CarritoReducer from './CarritoReducer'
import CarritoContext from './CarritoContext'
import { types } from '../types'
import { useSnackbar } from 'notistack';
import { MySuccessSnackbar } from '../../components/cart/MySuccessSnackbar';

 const CarritoState = (props) => {
    
    const initialState={
        productsInCart:[]
    }
    const [CartState, dispatch] = useReducer(CarritoReducer, initialState)
    const { enqueueSnackbar } = useSnackbar();

  const setProductCart = ( item , history, userAuth) => {
    const action = {
        type: types.setProductsCart,
        payload:item
      }
      dispatch( action )
    //   enqueueSnackbar(`${ item.nombre } Agregado al carrito`,{
    //     variant:'success',
    //     autoHideDuration: 1500,
    //     anchorOrigin: {
    //         vertical: 'top',
    //         horizontal:'right'
    //     }
    //   })

    enqueueSnackbar(<MySuccessSnackbar item={item} history={history} userAuth={userAuth}/>, { 
        variant: 'success',
        autoHideDuration: 5000,
        // anchorOrigin: {
        //     vertical: 'top',
        //     horizontal:'right'
        // }
    })

  }
  const removeProductCart = ( item ) => {
    const action = {
        type: types.removeProductCart,
        payload: item
      }
      dispatch( action )

      enqueueSnackbar(`${ item.nombre } Eliminado del carrito`,{
        variant:'error',
        autoHideDuration: 5000,
        // anchorOrigin: {
        //     vertical: 'top',
        //     horizontal:'right'
        // }
      })
  }

  const clearCart = () => {
    const action = {
      type: types.clearCart
    }

    dispatch( action )

    enqueueSnackbar(`Carrito vacio`,{
      variant:'error',
      autoHideDuration: 5000,
      // anchorOrigin: {
      //     vertical: 'top',
      //     horizontal:'right'
      // }
    })


  }


    return (
        <CarritoContext.Provider value={{
            CartState,

                //Methods
            removeProductCart,
            setProductCart,
            clearCart
        }}>
            {props.children}
        </CarritoContext.Provider>
    )
}
export default CarritoState;