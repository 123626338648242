import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./Tienda.css";
import getInventario from "../../Services/GetInventory";
import axios from "axios";
import Galeria from "./Galeria/Galeria";
import {
  GetInventaryTienda,
  GetInventaryTiendaTipo,
  GetInventaryTiendaPrecio,
  GetSkinsByName,
} from "../../Services/GetInventoryTienda";
import { TituloPaginaV3 } from "../../components/TitulosHeader/TituloPagina";
import {
  DropdownButton,
  Dropdown,
  Form,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import RangoPrecio from "./range/RangoPrecio";
import RangoPreciosV2 from "./range/RangoPreciosV2";
import Path from "../../Constantes/Constantes";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import Chip from "@material-ui/core/Chip";
import Spinner from "react-bootstrap/Spinner";
import CarritoContext from "../../Contexto/Carrito/CarritoContext";
import Francotirador from "../../img/weapons/dragonlore steam.png";
import Escopeta from "../../img/weapons/nova steam.png";
import Pistola from "../../img/weapons/usp steam.png";
import Rifleasalto from "../../img/weapons/vulcan steam.png";
import Subfusil from "../../img/weapons/ump steam.png";
import Guantes from "../../img/weapons/vice steam.png";
import Knife from "../../img/weapons/mariposa steam.png";
import Agente from "../../img/weapons/ava steam.png";
import Otros from "../../img/weapons/otros.png";
import SearchIcon from "@material-ui/icons/Search";
import { Redirect } from "react-router-dom";
import { Search } from "../../components/search/Search";
import { OrderMenu } from "../../components/order/OrderMenu";
import { OrderBy } from "../../Services/orderBy";
import { getInveWhitSearch, getNewInve } from "../../Services/GetInventorycopy";
import { UiContext } from "../../Contexto/ui/UiContext";
import { eliminarArticulos } from "../../Services/EditarEstadoItem";
import Swal from "sweetalert2";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const Tienda = ({ usuarioData,data }) => {
  let history = useHistory();
  const { setProductCart, CartState } =  useContext(CarritoContext);
  const { productsInCart } =CartState
    const { UiState, currency } = useContext( UiContext )
    const { currency: currentCurrency, dolar, dolarComision } = UiState
  // const [redirect, setRedirect] = useState(!data.userAuth);
  const [inventary, setInventary] = useState({});
  const [loading, setLoading] = useState(true);
  const [itemsCarrito, setItemsCarrito] = useState([]);
  const [precioCarrito, setPrecioCarrito] = useState(0);
  const [precioComparador, setPrecioComparador] = useState([0, 650000]);
  const [tipoComparador, setTipoComparador] = useState("Todo");
  const [limit, setLimit] = useState(30);
  const [limitPrice, setLimitPrice] = useState(30);
  const [limitCat, setLimitCat] = useState(30);
  const [lastFilter, setLastFilter] = useState(null);
  const [skinName, setSkinName] = useState({ name: "" });
  const [loadButonVerMas, setLoadButonVerMas] = useState(false);
  const [noMoreItems, setNoMoreItems] = useState(false);
  const [refresh, setRefresh] = useState(false)
// console.log(precioComparador)
const [search, setSearch] = useState('')
const [order, setOrder] = useState("")
const debounceRef = useRef()
// console.log(limit)
// console.log(inventary.length)
// console.log(inventary)
let currentSelected

if ( currentCurrency === 'ARS' ){
  currentSelected = dolar
}else if( currentCurrency === 'USD'){
  currentSelected = dolarComision
}
// useEffect(()=>{
//  console.log(refresh)
// }, [])
useEffect(() => {

    GetInventaryTienda().then((res) => {

      setInventary(res);
      setLoading(false);
    });
}, [refresh])

useEffect(() => {
  if ( currentCurrency === 'ARS' ){
    setPrecioComparador([0, 650000])
  }else if ( currentCurrency === 'USD' ){
    
    setPrecioComparador([0, 1500])
  }
  
}, [currentCurrency])

useEffect(() => {
  setLimit(30)
}, [tipoComparador])



useEffect( () => {
  setLoading(true)
 
  if ( search.length >=1){
    
    if ( debounceRef.current )
    clearTimeout( debounceRef.current );

    debounceRef.current = setTimeout( () => {
 

      getInveWhitSearch( search ).then(resp => {

        let items = []

        switch (tipoComparador) {
          case 'Todo':
            items =  resp.filter( r =>{
        
              return    (r.precio * currentSelected) >= precioComparador[0] && (r.precio * currentSelected) <= precioComparador[1]
               
               } )
         
               setInventary(items) 
          return  setLoading(false)

          case 'Otros':

            const filterOtros = resp.filter( r => 
              !(r.tipo).includes('Pistola')  &&
              !(r.tipo).includes('Rifle')  &&
              !(r.tipo).includes('Subfusil')  &&
              !(r.tipo).includes('Cuchillo')  &&
              !(r.tipo).includes('Guantes')  &&
              !(r.tipo).includes('Rifle de francotirador')  &&
              !(r.tipo).includes('Agente')  &&
              !(r.tipo).includes('Ametralladora')  &&
              !(r.tipo).includes('Escopeta')
              
              
            )

            items = filterOtros.filter( r => (r.precio * currentSelected) >= precioComparador[0] && (r.precio * currentSelected) <= precioComparador[1] )

            setInventary(items)
            return setLoading(false)

          default:
            items = resp.filter( r => (r.precio * currentSelected) >= precioComparador[0] && (r.precio * currentSelected) <= precioComparador[1] && r.tipo === tipoComparador)
       
            setInventary(items) 
           return setLoading(false)
        }

      });

     
    }, 450 );
    
  }else {
    cambiarCategoria(tipoComparador)
  }
  
}, [search,tipoComparador,precioComparador]);



// console.log(inventary)

const handleOrder = (e) => {
  setOrder(e)
}


  async function cambiarCategoria(
    tipo,
    cant = limitCat,
    setearLoading = setLoading
  ) {
    
    setTipoComparador(tipo);
    setLastFilter("cat");
    if(search.length === 0){
    setearLoading(true);

    if(search.length === 0){


      switch (tipo) {
        case "Todo":
         return await GetInventaryTiendaPrecio(precioComparador, cant , currentSelected).then((res) => {
            setInventary(res);
            setearLoading(false);
          });
        case "Otros":
          return await GetInventaryTiendaPrecio(precioComparador, cant , currentSelected).then((res) => {
          
            const filterOtros = res.filter( r => 
              !(r.tipo).includes('Pistola')  &&
              !(r.tipo).includes('Rifle')  &&
              !(r.tipo).includes('Subfusil')  &&
              !(r.tipo).includes('Cuchillo')  &&
              !(r.tipo).includes('Guantes')  &&
              !(r.tipo).includes('Rifle de francotirador')  &&
              !(r.tipo).includes('Agente')  &&
              !(r.tipo).includes('Ametralladora')  &&
              !(r.tipo).includes('Escopeta')  
              
              
              )
            setInventary(filterOtros);
            setearLoading(false);
          });
      
        default:
          return await GetInventaryTiendaTipo(tipo, precioComparador, cant , currentSelected).then((res) => {
          setInventary(res);
          setearLoading(false);
        });
      }


    }

     }
  
  }


  function handlerSeeMoreItems() {
    var cant = 30;
    setLimit( limit + cant)
  }

  function handleInputAddItem(item) {
    // const found = carrito.find((element) => element?.id == item?.id);
    const found = productsInCart.find((element) => element.id === item?.id);
    if (found == undefined) {
      // setItemsCarrito((itemsCarrito) => [...itemsCarrito, item]);
      // setPrecioCarrito(parseInt(precioCarrito) + parseInt(item?.precio));
      // setCarrito([...carrito, item]);
      // setPrecioTotal(precioTotal + parseInt(item?.precio));
    // }


    setProductCart(item , history, data.userAuth)
    }

  }

  OrderBy( order , inventary )


  const handleCurrency = (event) => {
    // console.log(event.target.value)
    currency(event.target.value)
  }

  const handleDeleteAll = () => {

    Swal.fire({
      title: 'Estás seguro?',
      text: "Si borras todos los productos,esta acción no puede revertirse",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Si, borrar todo!',
      cancelButtonText: 'Cacelar'
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarArticulos(inventary)
        
        let timerInterval
        Swal.fire({
          title: 'Eliminando productos',
          html: 'Espere por favor... <b></b> .',
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            
            setRefresh(!refresh)
          }
        })
      }
    })
  }

  // if (!data.userAuth) {
  //   setRedirect(true);
  // }

  // if (redirect) {
  //   return <Redirect to="/" />;
  // }

  return (
    <div className="contenedor-tienda">

      {
        data.usuarioData && data.usuarioData.tipo === "Administrador" && (

          <div style={{ position: 'relative' }}>

            <button 
              className="btn btn-primary" 
              style={{ position: 'absolute' , right: '10px', top: '10px'}}
              onClick={handleDeleteAll}
              >
              Eliminar todos 
            </button>
          </div>
        )
      }

      <h3>Encontra tu skin ideal, al mejor precio! </h3>

      <div className=" search-order" style={{ display: 'flex' }}>

        <div className='col-9  search-box'>

          <Search search={ search } setSearch={ setSearch } />
        </div>

        <div className="col-3 ">

          <div className="row" style={{ alignItems: 'center' }}>

            <div className="col-3">

              <OrderMenu handleOrder={handleOrder}  />
            </div>
            <div className="col-2">

              <select name="currency" id=""  style={{ width: '100px' }} onChange={ handleCurrency }>
                <option value="ARS" selected={ currentCurrency === 'ARS' ? true : false }>ARS</option>
                <option value="USD" selected={ currentCurrency === 'USD' ? true : false }>USD</option>

              </select>
            </div>
          </div>

        </div>

      </div>


      <div className="select-category">
        <div className="rango-precios-izquierda">
          <RangoPrecio precioComparador={precioComparador} setPrecioComparador={setPrecioComparador}/>
        </div>
        {/* <RangoPreciosV2 /> */}
        <ButtonGroup
          size="large"
          variant="text"
          color="primary"
          aria-label="text primary button group"
        >
          <div
            className={
              tipoComparador !== "Todo"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Todo")}
          >
            <h1>Todo</h1>
          </div>
          <div
            className={
              tipoComparador !== "Cuchillo"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Cuchillo")}
            // onClick={() => cambiarCategoria("Cuchillo")}
          >
            <img src={Knife} className="img-weapon" alt="a" />
            <h1>Cuchillos</h1>
          </div>
          <div
            className={
              tipoComparador !== "Rifle"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Rifle")}
          >
            <img src={Rifleasalto} className="img-weapon" alt="a" />
            <h1>Rifles</h1>
          </div>
          <div
            className={
              tipoComparador !== "Pistola"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Pistola")}
            // onClick={() => cambiarCategoria("Pistola")}
          >
            <img src={Pistola} className="img-weapon" alt="a" />
            <h1>Pistolas</h1>
          </div>
          <div
            className={
              tipoComparador !== "Escopeta"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Escopeta")}
            // onClick={() => cambiarCategoria("Escopeta")}
          >
            <img src={Escopeta} className="img-weapon" alt="a" />
            <h1>Escopetas</h1>
          </div>
          <div
            className={
              tipoComparador !== "Subfusil"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Subfusil")}
            // onClick={() => cambiarCategoria("Subfusil")}
          >
            <img src={Subfusil} className="img-weapon" alt="a" />
            <h1>Subfusiles</h1>
          </div>
          <div
            className={
              tipoComparador !== "Guantes"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Guantes")}
            // onClick={() => cambiarCategoria("Guantes")}
          >
            <img src={Guantes} className="img-weapon" alt="a" />
            <h1>Guantes</h1>
          </div>
          <div
            className={
              tipoComparador !== "Rifle de francotirador"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Rifle de francotirador")}
            // onClick={() => cambiarCategoria("Rifle de francotirador")}
          >
            <img src={Francotirador} className="img-weapon" alt="a" />
            <h1>Snipers</h1>
          </div>
          <div
            className={
              tipoComparador !== "Agente"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Agente")}
          >
            <img src={Agente} className="img-weapon" alt="a" />
            <h1>Agentes</h1>
          </div>

          <div
            className={
              tipoComparador !== "Otros"
                ? "contenedor-tipos-img"
                : "contenedor-tipos-img hover-item"
            }
            onClick={() => cambiarCategoria("Otros")}
          >
            <img src={Otros} className="img-weapon img-otro" alt="a" />
            <h1>Otros</h1>
          </div>

        </ButtonGroup>
      </div>

      {loading ? (
        <div className="spinner-contenedor">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="margin-max-resolution">
          {inventary.length == 0 ? (
            <h1 className="tienda-no-results-label">No hay resultados</h1>
          ) : (
            <>
              <Galeria
           
                handleInputAddItem={handleInputAddItem}
                items={inventary.slice(0, limit )}
                usuarioData={data.usuarioData}
              />
              {loadButonVerMas ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <Button
                  className="tienda-boton-ver-mas"
                  onClick={handlerSeeMoreItems}
                  variant="outline-danger"
                  style={{ display: limit >= inventary.length && 'none'  }}
                >
                  Ver mas
                </Button>
              )}
              {noMoreItems ? (
                <h1 className="no-more-items-text">No hay mas resultados</h1>
              ) : null}
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Tienda;

// async function getinventario() {
//   await axios
//     .get(`/id/emmik/inventory/json/730/2`)
//     .then((res) => {
//       var ids = res.data.rgInventory;
//       var item = res.data.rgDescriptions;

//       debugger;
//       for (var i = 0; i < ids.length; i++) {
//         for (var k = 0; k < item.length; k++) {
//           if (ids[i].classid == item[k].classid) {
//             ids[i].market_name = item[k].market_name;
//             ids[i].icon_url = item[k].icon_url;
//             ids[i].tradable = item[k].tradable;
//           }
//         }
//       }

//       setInventay(ids);
//     })
//     .then(() => {
//       setLoading(false);
//     })
//     .catch((err) => console.log(err));
// }

// async function getInv() {
//   await fetch("/id/emmik/inventory/json/730/2", {
//     method: "GET",
//     headers: new Headers({ "Content-type": "application/json" }),
//     mode: "no-cors",
//   })
//     .then((response) => response.json())
//     .then((res) => {
//       const { rgDescriptions } = res;
//       var inventarioFinal = [];
//       for (const property in rgDescriptions) {
//         var item = {
//           nombre: rgDescriptions[property].name,
//           foto: rgDescriptions[property].icon_url_large,
//         };
//         inventarioFinal.push(item);
//       }
//       setInventary(inventarioFinal);
//     })
//     .then(() => {
//       setLoading(false);
//     });
// }
