import { Badge, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

export const CartIcon = ({productsInCart,color = 'grey', showPrice, currency, dolar, precioFinal, dolarComision}) => {


  return (
    <ListItem button>
    <ListItemIcon>
      {productsInCart.length > 0 ? (
        <Badge
          badgeContent={productsInCart.length}
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <ShoppingCartIcon style={{ color: color}}/>
        </Badge>
      ) : (
        <ShoppingCartIcon />
      )}
    </ListItemIcon>

   
    
            {
            currency === 'ARS' && showPrice &&
            <ListItemText primary={`Comprar $${Math.round( precioFinal * dolar)}`} />
            }
            {
            currency === 'USD' && showPrice &&
            <ListItemText primary={`Comprar $${ (precioFinal * dolarComision).toFixed(2)}`} />
            }
    
  
 
  
    
  </ListItem>
  )
}
