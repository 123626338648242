import { useContext } from "react";
import { UiContext } from "../Contexto/ui/UiContext";
import { db } from "../firebase";
import { GetValorDolar } from "./GetValorDolar";

function roundPrice(n){
  //return Math.round((n + Number.EPSILON) * 100) / 100;
  return Math.ceil(n);
}

export async function GetInventaryTienda(limit) {

  var datos = [];
  // let dolar = await GetValorDolar();
// console.log(datos)
  var snapshot = await db
    .collection("tienda")
    .where("disponible", "==", true)
    // .where("precio", "<=", precio[1])
    // .where("precio", ">=", precio[0])
    .orderBy("precio", "asc")
    .limit(limit)
    .get();
  snapshot.docs.map((doc) => {
    datos.push(doc.data());
  });
  // for(let i=0;i<datos.length;i++){
  //   //datos[i]["precio"] *= dolar;
  //   datos[i]["precio"] = roundPrice(datos[i]["precio"]*dolar);
  // }

  return datos;
}
// export async function GetSkinsByName(name = "", limit) {
//   console.log(name);
//   var datos = [];
//   const snapshot = await db
//     .collection("tienda")
//     .where("nombre", "==", name)
//     .limit(limit)
//     .get();
//   snapshot.docs.map((doc) => {
//     console.log(datos);

//     datos.push(doc.data());
//     console.log(datos);
//   });
//   return datos;
// }
export async function GetSkinsByName(name = "a", limit) {
  var nameUpper = name.toUpperCase();
  var datos = [];
  // let dolar = await GetValorDolar();
  var snapshot = await db
    .collection("tienda")
    .orderBy("nombre", "asc")
    .startAt(["\uf8ff" + nameUpper])
    .endAt([nameUpper + "\uf8ff"])
    .where("disponible", "==", true)
    .limit(limit)
    .get();
  snapshot.docs.map((doc) => {
    datos.push(doc.data());
  });
  // for(let i=0;i<datos.length;i++){
  //   datos[i]["precio"] = roundPrice(datos[i]["precio"]*dolar);
  // }
  return datos;
}

export async function GetInventaryTiendaTipo(tipo, precio, limit , currentSelected) {
  var datos = [];
  // console.log(precio[1] / currentSelected)

  // let dolar = await GetValorDolar();
  var snapshot = await db
    .collection("tienda")
    // .startAt(startAt.id)
    .where("disponible", "==", true)
    .where("tipo", "==", tipo)
    .where("precio", "<=", (precio[1] / currentSelected))
    .where("precio", ">=", (precio[0] / currentSelected))
    .orderBy("precio", "asc")
    // .limit(limit)
    .get();
  snapshot.docs.map((doc) => {
    datos.push(doc.data());
  });
  // for(let i=0;i<datos.length;i++){
  //   datos[i]["precio"] = roundPrice(datos[i]["precio"]*dolar);
  // }
  return datos;
}

export async function GetInventaryTiendaPrecio(precio, limit, currentSelected) {
  
  var datos = [];
  // let dolar = await GetValorDolar();
  var snapshot = await db
    .collection("tienda")
    // .startAt(startAt.id)
    .where("disponible", "==", true)
    .where("precio", "<=", (precio[1] / currentSelected))
    .where("precio", ">=", (precio[0] / currentSelected))
    .orderBy("precio", "asc")
    // .limit(limit)
    .get();
  snapshot.docs.map((doc) => {
    datos.push(doc.data());
  });
  // for(let i=0;i<datos.length;i++){
  //   datos[i]["precio"] = roundPrice(datos[i]["precio"]*dolar);
  // }
  return datos;
}
