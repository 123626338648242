import { db } from "../firebase";

export async function GetCupon(cupon) {
  var datos = [];
  const snapshot = await db
    .collection("cupones")
    .where("codigo", "==", cupon)
    .get();
  snapshot.docs.map((doc) => {
    datos.push(doc.data());
  });

  return datos;
}
