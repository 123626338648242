import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./FinalizarCompra.css";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Path from "../../../Constantes/Constantes";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Social from "../../Home/Social/Social";
import Pdf from "react-to-pdf";
import { UiContext } from "../../../Contexto/ui/UiContext";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function VerticalLinearStepper({
  precioTotal,
  precioConPromocion,
  carrito,
  data,
}) {
  const [disabledChecks, setDisabledChecks] = useState(false);
  const [payMethodSelected, setPayMethodSelected] = useState(null);
  const { UiState } = useContext( UiContext )
  const { dolar, currency, dolarComision} = UiState

  let bitcoin = (
    <>
      <h5>BITCOIN </h5>
      <p>
        Aceptamos las redes BEP20 y BTC.
        <br />
        <br />
        Revisar bien qué red estas usando y elegir la wallet correspondiente a dicha red. Los errores en los envíos ocasionarán la pérdida de sus fondos por los cuales Blazer Store no se responsabiliza.
        <br />
        <br />
        Tener en cuenta las comisiones antes de enviar.
        <br />
        <br />
        Binance Smart Chain (BEP20)
        <br />
        Dirección: 0x45e509509d1afbff579ab40e7b3470f86fc671ce
        <br />
        Depósito mínimo 0.00000001 BTC Envíe solo BTC a esta dirección de depósito.
        <br />
        Asegúrate que la red sea Binance Smart Chain (BEP20).
        <br />
        <br />
        Bitcoin (BTC) - Dirección: 15BaZFbbAMonmWFWJuJS14f7BucGs3heUZ
        <br />
        Depósito mínimo 0.00000001 BTC
        <br />
        Envíe solo BTC a esta dirección de depósito. Asegúrate que la red sea Bitcoin.

      </p>
    </>
  );
  let tether = (
    <>
     <h5>USDT</h5>
      <p>
        Aceptamos las redes BEP20 y TRC20. 
        <br />
        <br />
        Revisar bien qué red estas usando y elegir la wallet correspondiente a dicha red. Los errores en los envíos ocasionarán la pérdida de sus fondos por los cuales Blazer Store no se responsabiliza.
        <br />
        <br />
        Tener en cuenta las comisiones antes de enviar.
        <br />
        <br />
        Binance Smart Chain (BEP20)
        <br />
        Dirección: 0x45e509509d1afbff579ab40e7b3470f86fc671ce
        <br />
        Depósito mínimo 0.00000001 USDT Envía solo USDT a esta dirección de depósito.
        <br />
        Asegúrate que la red sea Binance Smart Chain (BEP20).
        <br />
        <br />
        Tron (TRC20) - Dirección: TWAQQ3ySxZEwM7BPzZm8wPAMSoDf4m7Dc3
        <br />
        Depósito mínimo 0.00000001 USDT
        <br />
        Envía solo USDT a esta dirección de depósito. Asegúrate que la red sea Tron (TRC20).
      </p>
    </>
  );
  let Ethereum = (
    <>
      <h5>ETHEREUM</h5>
      <p>
        Aceptamos las redes BEP20 y TRC20. 
        <br />
        <br />
        Revisar bien qué red estas usando y elegir la wallet correspondiente a dicha red. Los errores en los envíos ocasionarán la pérdida de sus fondos por los cuales Blazer Store no se responsabiliza.
        <br />
        <br />
        Tener en cuenta las comisiones antes de enviar.
        <br />
        <br />
        Binance Smart Chain (BEP20)
        <br />
        Dirección: 0x45e509509d1afbff579ab40e7b3470f86fc671ce
        <br />
        Depósito mínimo 0.00000001 ETH
        <br />
        Envía solo ETH a esta dirección de depósito.
        <br />
        Asegúrate que la red sea Binance Smart Chain (BEP20).
        <br />
        <br />
        Ethereum (ERC20)
        <br />
        Dirección: 0x45e509509d1afbff579ab40e7b3470f86fc671ce
        <br />
        Depósito mínimo 0.00000001 ETH Envía solo ETH a esta dirección de depósito.
        <br />
        Asegúrate que la red sea Ethereum (ERC20).

      </p>
    </>
  );

  let Litecoin = (
    <>
      <h5>LITECOIN</h5>
      <p>
      Aceptamos las redes BEP20 y LTC.
        <br />
        <br />
        Revisar bien qué red estas usando y elegir la wallet correspondiente a dicha red. Los errores en los envíos ocasionarán la pérdida de sus fondos por los cuales Blazer Store no se responsabiliza.
        <br />
        <br />
        Tener en cuenta las comisiones antes de enviar.
        <br />
        <br />
        Binance Smart Chain (BEP20)
        <br />
        Dirección: 0x45e509509d1afbff579ab40e7b3470f86fc671ce
        <br />
        Depósito mínimo 0.00000001 LTC
        <br />
        Envía solo LTC a esta dirección de depósito.
        <br />
        Asegúrate que la red sea Binance Smart Chain (BEP20).
        <br />
        <br />
        Litecoin (LTC)
        <br />
        Dirección: Lgpejq2fTVVRYmnmgr5Ps2jWG4nfSf5fFx
        <br />
        Depósito mínimo 0.001 LTC Envía solo LTC a esta dirección de depósito.
        <br />
        Asegúrate que la red sea Litecoin (LTC).

      </p>
    </>
  );

  let arrPagos =[]
  
  if ( currency === 'ARS' ){
    arrPagos =  [
      { id: 0, name: "Transferencia bancaria" },
      { id: 1, name: "Mercadopago (Unicamente con plata cargada en cuenta)" },
      ,
      { id: 2, name: "Ualá" },
      ,
      { id: 3, name: "Brubank" },
      { id: 4, name: "Bitcoin (BTC)" },
      { id: 5, name: "Tether (USDT)" },
      { id: 6, name: "Ethereum (ETH)" },
      { id: 7, name: "Litecoin (LTC)" },

      {
        id: 8,
        name: "Si tienes otro metodo de pago puedes consultarnos en nuestras redes!",
      },
    ]
  }

  if ( currency === 'USD') {
    arrPagos = [
      { id: 4, name: "Bitcoin (BTC)" },
      { id: 5, name: "Tether (USDT)" },
      { id: 6, name: "Ethereum (ETH)" },
      { id: 7, name: "Litecoin (LTC)" },
    ]
  }


  const textPagos = [
    {
      id: 0,
      text: "Pedir datos de pago por cualquiera de nuestras redes sociales.",
    },
    {
      id: 1,
      text: "Pedir datos de pago por cualquiera de nuestras redes sociales.",
    },
    {
      id: 2,
      text: "Pedir datos de pago por cualquiera de nuestras redes sociales.",
    },
    {
      id: 3,
      text: "Pedir datos de pago por cualquiera de nuestras redes sociales.",
    },
    {
      id: 4,
      text: bitcoin,
    },
    {
      id: 5,
      text: tether,
    },
    {
      id: 6,
      text: Ethereum,
    },
    {
      id: 7,
      text: Litecoin,
    },
  ];

  function getSteps() {
    return ["Datos Personales", "Metodos de Pago", "Gracias por elegirnos!"];
  }
  const ref = React.createRef();

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function handleCheck(ide, name) {
    setPayMethodSelected({ id: ide, nombre: name });
    setDisabledChecks(true);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" name="Nombre" />

            <Form.Label>Apellido</Form.Label>
            <Form.Control type="text" name="Apellido" />

            <Form.Label>Pais</Form.Label>
            <Form.Control type="text" name="Pais" />
          </>
        );
      case 1:
        return (
          <ul className="ul-items">
            {arrPagos?.map((item) => {
              return (
                <li>
                  <FormControl required component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={disabledChecks}
                            onChange={() => handleCheck(item.id, item.name)}
                            name={item.name}
                          />
                        }
                        label={item.name}
                      />
                    </FormGroup>
                  </FormControl>
                </li>
              );
            })}
          </ul>
        );
      case 2:
        return (
          <div className="contenedor-step-3">
            <h1>
              Comunicate con nosotros por cualquiera de nuestras redes sociales
              para coordinar la entrega del pedido , adjuntando el comprobante
              generado. Al clickear en finalizar te redirigiremos a nuestro
              Whatsapp.
            </h1>
            <Social colorNegro={true} />
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const handleNext = (toPdf) => {
    if (activeStep === steps.length - 2) {
      toPdf();
    }
    if (activeStep === steps.length - 1) {
      window.open("https://api.whatsapp.com/message/JPG7W4I2LY4YJ1", "_blank");
      window.location.href = Path.SITE_URL;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="contenedor-padre-finalizar-all">
      <div className="contenedor-flex-ro">
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0 || disabledChecks}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Atras
                      </Button>
                      <Pdf targetRef={ref} filename="BlazerStoreCsgo.pdf">
                        {({ toPdf }) => (
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={
                              !payMethodSelected && activeStep === 1
                                ? true
                                : false
                            }
                            onClick={() => handleNext(toPdf)}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1
                              ? "Finalizar"
                              : "Siguiente"}
                          </Button>
                        )}
                      </Pdf>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>
                Has completdo todos los pasos, en breve se descargara un
                comprobante
              </Typography>
              {/* <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button> */}
            </Paper>
          )}
        </div>
        <div className="detalles-finalizar-compra">
          <div className="items-juntos">
            {carrito?.map((item) => {
              return (
                <div className="item-finalizar-compra">
                  <h6>{item?.nombre}</h6>
                </div>
              );
            })}
          </div>

          <h1>
            SUBTOTAL:{"  $"}
            {precioConPromocion != 0
              ? precioTotal - (precioTotal * precioConPromocion) / 100
              : precioTotal}
          </h1>
        </div>
      </div>
      <div>
        {/* COMPROBANTE */}

        <div className="comprobante" ref={ref}>
          <h1>Comprobante de compra </h1>
          <div className="comprobante-body">
            <h2>Comprador: {data.usuarioData.nombre}</h2>
            <h2>Email: {data.usuarioData.email}</h2>
            <h2>
              Nacionalidad:{" "}
              {data.usuarioData.nacionalidad
                ? data.usuarioData.nacionalidad
                : "Sin Nacionalidad"}
            </h2>

            <h2>
              Telefono:{" "}
              {data.usuarioData.telefono
                ? data.usuarioData.telefono
                : "Sin numero"}
            </h2>

            <h2>Monto total :$ 
              {
                currency === 'ARS' &&

                (precioTotal * dolar).toFixed(2)
              }
              {
                currency === 'USD' &&

                (precioTotal * dolarComision).toFixed(2)
              }
              
              </h2>
            <div className="contenedor-descuento">
              <h1>Descuento</h1>
              {
                currency === 'ARS' &&

                <h2>-${(((precioTotal * dolar) * precioConPromocion) / 100).toFixed(2)}</h2>
                
              }
              {
                currency === 'USD' &&

                <h2>-${(((precioTotal * dolarComision) * precioConPromocion) / 100).toFixed(2)}</h2>
                
              }
            </div>
            <h2 className="precio-verde-grande">
              Monto final :
              <span>
                $
                {
                currency === 'ARS' &&
                  <>
                    {precioConPromocion != 0
                      ? ((precioTotal - (precioTotal * precioConPromocion) / 100) * dolar).toFixed(2)
                      :( precioTotal * dolar).toFixed(2)}
                  
                  </>
           
              }
                {
                currency === 'USD' &&
                  <>
                    {precioConPromocion != 0
                      ? ((precioTotal - (precioTotal * precioConPromocion) / 100) * dolarComision).toFixed(2)
                      : (precioTotal * dolarComision).toFixed(2)}
                  
                  </>
           
              }
              </span>
            </h2>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre Skins</th>
                <th>Precio</th>
                <th>Float</th>
                <th>Entrega</th>
              </tr>
            </thead>
            {carrito?.map((item) => {
              return (
                <tbody className="contenedor-data-item-comprobante">
                  <tr>
                    <td>
                      <h1>{item?.nombre}</h1>
                    </td>
                    <td>
                      {
                         currency === 'ARS' &&
                      <h2>${((item?.precio) * dolar).toFixed(2)}</h2>
                      }
                      {
                         currency === 'USD' &&
                      <h2>${((item?.precio) * dolarComision).toFixed(2)}</h2>
                      }
                    </td>
                    <td>
                      <h2>{item?.floatValue}</h2>
                    </td>
                    <td>
                      {new Date(item.cacheExpiration) > new Date() ? (
                        <h3>
                          Disponible el:{"  "}
                          {new Date(item?.cacheExpiration).toLocaleDateString()}
                        </h3>
                      ) : (
                        <h4>Disponible</h4>
                      )}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </Table>

          <h3 className="metodos-pago-head">
            Metodo de pago seleccionado{" "}
            {payMethodSelected?.nombre ? payMethodSelected?.nombre : ""}
          </h3>
          <h4 className="metodos-pago-head">
            Los pasos a seguir son los siguientes:
          </h4>

          {payMethodSelected ? (
            <p className="parrafo-info-pago">
              - {textPagos[payMethodSelected?.id].text}
            </p>
          ) : null}

          {payMethodSelected?.id < 4 ? (
            <>
              <Social navBar={true} />
              <p>@BlazerStoreCSGO</p>
            </>
          ) : null}
        </div>

        {/* COMPROBANTE */}
      </div>
    </div>
  );
}
