
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"
import './search.css'
export const Search = ( { search,setSearch } ) => {

    const onSearchChange = ({target}) =>{
      // e.preventDefault()
    //   setcurrentSelected('all')


      setSearch( target.value )
      }

  return (
    <form 
    onSubmit={ onSearchChange }
    className=" formSerchSteam"
    style={{ display:'flex', justifyContent: 'center', marginBottom: -30}}

  >
    
      <input 
          autoComplete="off"
          className="search col-10"
          name="search"
          onChange={ onSearchChange }
          placeholder="buscar item"
          type="search default" 
          value= { search } 
        
      ></input>

    <div style={{ marginLeft: -28 , marginTop: 5, zIndex: 10}} >
    
    {search === ''
      ?
  
      <SearchIcon  />
      :

      <div className="closeIcon" onClick={()=> setSearch('')}>

        <CloseIcon  />
      </div>
   
      }

    </div>
    <button type="submit" disabled hidden aria-hidden="true"></button>
  </form> 
  )
}