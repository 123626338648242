import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
//FIN DE IMPORTS

//CONFIGURACIÓN FIREBASE
const config = {

  
    apiKey: "AIzaSyDZOcj0E-hUDO46OwWP8Fg6tcUmhcERHWQ",
    authDomain: "stgo-76200.firebaseapp.com",
    projectId: "stgo-76200",
    storageBucket: "stgo-76200.appspot.com",
    messagingSenderId: "403642506573",
    appId: "1:403642506573:web:b7d057d57de0b74a50eb04",
    measurementId: "G-89XSXD26TR"
  };

//INICIALIZACIÓN DE LA APP
const app = firebase.initializeApp(config);

//DB
export const db = app.firestore();

//AUTENTICACIÓN
export const auth = app.auth();

//AUTENTICACIÓN CON PROVIDER DE GOOGLE
export const providerGoogle = new firebase.auth.GoogleAuthProvider();

//AUTENTICACIÓN CON PROVIDER DE FACEBOOK
export const providerFacebook = new firebase.auth.FacebookAuthProvider();

// avoid deprecated warnings
db.settings({
  timestampsInSnapshots: true,
});
