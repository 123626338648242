import React, { useState } from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import "./FloatChat.css";

const FloatChat = () => {
  const [showChat, setShowChat] = useState(true);
  return (
    <div>
      {showChat ? (
        <>
          <MessengerCustomerChat
            pageId="188074751525983"
            appId="869952176954644"
            onCustomerChatDialogShow="Hola ¿te puedo ayudar?"
            minimized={true}
          />
          <p className="cerrar-chat-text" onClick={() => setShowChat(false)}>
            Dejar de mostrar chat!
          </p>
        </>
      ) : null}
    </div>
  );
};

export default FloatChat;

//  <div>
//    {showChat ? (
//      <>
//        <MessengerCustomerChat
//          pageId="188074751525983"
//          appId="869952176954644"
//          shouldShowDialog={false}
//          onCustomerChatDialogShow="Hola ¿te puedo ayudar?"
//          minimized={true}
//          language="es_ES"
//          themeColor="red"
//        />
//        <p className="cerrar-chat-text" onClick={() => setShowChat(false)}>
//          Dejar de mostrar chat!
//        </p>
//      </>
//    ) : null}
//  </div>;
