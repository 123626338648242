import React from "react";
import "./TituloPagina.css";
import { RandomReveal } from "react-random-reveal";

const TituloPagina = ({ titulo }) => {
  return (
    <div className="contenedor-titulo-tienda">
      <div className="box">
        <div className="lightbar"></div>
        <div className="topLayer"></div>
        <h2>{titulo}</h2>
      </div>
      {/* <h1 className="titulo-tienda">Tienda</h1> */}
    </div>
  );
};
export default TituloPagina;

export const TituloPaginaV2 = () => {
  return (
    <div className="titulodePagina-v2">
      <h2>
        <span>Tw2n%/1</span>
        <span>11e$"a</span>
        <span>_i?nÑa</span>
        <span>T1e¨*da</span>
        <span>T#!&a</span>
        <span>Tienda</span>
        <span>B$a!#r S&*re</span>
        <span>6l&z%r #t!re</span>
        <span>8liz*r Store</span>
        <span>Blazer Store</span>
      </h2>
    </div>
  );
};

export const TituloPaginaV3 = ({ titulo }) => {
  return (
    <div className="demo contenedor-titulo-tienda">
      <h2>
        <RandomReveal
          className="titulodePagina-v3"
          isPlaying
          speed={4}
          duration={2}
          revealDuration={0.4}
          characters={titulo}
          onComplete={() => [true, 8500]}
        />
      </h2>
    </div>
  );
};
