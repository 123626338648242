import React from "react";
import "./Contacto.css";
import twLogo from "../../img/twLogo.png";
import fbLogo from "../../img/fbLogo.png";
import instaLogo from "../../img/instaLogo.png";
import wtspLogo from "../../img/wtspLogo.png";

const Contacto = () => {
  return (
    <div className="contenedor-contactos-logo">
      <a href="https://twitter.com/blazerstorecsgo" target="blank">
        <img src={twLogo} alt="" />
      </a>
      <a href="https://www.facebook.com/blazerstorecsgo/" target="blank">
        <img src={fbLogo} alt="" />
      </a>
      <a href="https://www.instagram.com/blazerstorecsgo/" target="blank">
        <img src={instaLogo} alt="" />
      </a>
      <a href="https://api.whatsapp.com/message/JPG7W4I2LY4YJ1" target="blank">
        <img src={wtspLogo} alt="" />
      </a>
    </div>
  );
};
export default Contacto;
