import axios from "axios";
import Path from "../Constantes/Constantes";

export async function GetFloat(assetId, rungame) {
  const url =
    "https://api.csgofloat.com/?url=steam://rungame/730/76561202255233023/+csgo_econ_action_preview%20S76561198877233929A22115556455D3072796368979775338";
  var datos = [];
  return await fetch(`https://api.csgofloat.com/?url=${rungame}`, {
    method: "GET",
    headers: new Headers({ "Content-type": "application/json" }),
    mode: "no-cors",
  })
    .then((response) => response.json())
    .then((res) => {});
}

export async function GetFloatAxios(rungame) {
  if (rungame != "") {
    return axios
      //.get(
      //  `${Path.INVENTARY_BASE_URL}/api/?url=https://api.csgofloat.com/?url=${rungame}`
      //)
      .get(
        `${Path.INVENTARY_BASE_URL}/api/?url=https://api.csgofloat.com/?url=${rungame}`
      )
      .then((res) => {
        if (res.status == 200) {
          return res.data.iteminfo;
        } else {
          return "-";
        }
      });
  }
}
