import React, { useState, useEffect } from "react";
import "./Galeria.css";
import CardItem from "../../../components/Card/CardItem";
import CardMaterialUi from "../../../components/Card/CardMaterialUI/CardMaterialUi";
import CardItemAdmin from "../../../components/Card/CardItemAdmin/CardItemAdmin";
import { CardDeck } from "react-bootstrap";

const Galeria = ({ handleInputAddItem, items, fuente, usuarioData }) => {
  const itemstotales = items

  return (
    <div className="galeria-contenedor">
      {fuente !== "Administrador"
        ? itemstotales.map((item) => (
            <CardMaterialUi
              key={item.id}
              handleInputAddItem={handleInputAddItem}
              itemUnico={item}
              usuarioData={usuarioData}
            />
          ))
        : null}
      {/* // : itemstotales.map((item) => <CardItemAdmin itemUnico={item} />)} */}
    </div>
  );
};

export default Galeria;
