const Paths = {
  IMAGEN_URL: "https://steamcommunity-a.akamaihd.net/economy/image/",
  SITE_URL: "https://blazerstorecsgo.com/",
  INVENTARY_BASE_URL: "https://plankton-app-757hf.ondigitalocean.app",
};

export default Paths;

//https://stgo-76200.web.app/
//https://stgo-nahuel-scerca.vercel.app/
//https://blazerstorecsgo.com/
