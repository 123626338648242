import axios from "axios";
import Path from "../Constantes/Constantes";
import { GetInventaryTienda } from "./GetInventoryTienda";

//"proxy": "https://steamcommunity.com",https://stgoback-8vgft.ondigitalocean.app/api/?url=https://steamcommunity.com/id/emmik/inventory/json/730/2
export default async function getInv({ keyword, id64 }) {
  var inventarioFinal = [];

  const axiosConfig = {
    withCredentials: false,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/x-www-form-urlencoded",
    },
  };
  // https://stgoback-o4okd.ondigitalocean.app/
  // `https://stgoback-8vgft.ondigitalocean.app/api/
  return await axios
    //.get(
    //  `${Path.INVENTARY_BASE_URL}/api/?url=https://steamcommunity.com/id/${keyword}/inventory/json/730/2/?l=spanish`
    //)
    .get(
      `${Path.INVENTARY_BASE_URL}/api/?url=url=https://steamcommunity.com/inventory/${id64}/730/2/?l=spanish`
    )
    .then((res) => {
      // console.log(res);
      if (res.data.success) {
        const { rgDescriptions, rgInventory } = res.data;
        for (const property in rgDescriptions) {
          var itemId = "";
          for (const propiedad in rgInventory) {
            if (
              rgInventory[propiedad].classid ==
                rgDescriptions[property].classid &&
              rgInventory[propiedad].instanceid ==
                rgDescriptions[property].instanceid
            ) {
              itemId = rgInventory[propiedad].id;
            }
          }

          var lastActionsLink = rgDescriptions[property].actions
            ? rgDescriptions[property].actions[0].link
            : "";
          if (lastActionsLink != "" || lastActionsLink != undefined) {
            lastActionsLink = lastActionsLink.replace("%owner_steamid%", id64);
            lastActionsLink = lastActionsLink.replace("%assetid%", itemId);
          }
        
          var item = {
            nombre: rgDescriptions[property].market_name,
            foto: rgDescriptions[property].icon_url,
            descripcion: rgDescriptions[property].descriptions,
            classid: rgDescriptions[property].classid,
            instanceid: rgDescriptions[property].instanceid,
            cacheExpiration: rgDescriptions[property].cache_expiration
              ? rgDescriptions[property].cache_expiration
              : "",
            actionsLink: lastActionsLink,
            tipo: rgDescriptions[property].tags[0].name
              ? rgDescriptions[property].tags[0].name
              : "",
            assetID: itemId,
            floatValue: ""
          };
          inventarioFinal.push(item);
        }
      } else {
        return {
          loading: true,
          arrayInventario: inventarioFinal,
          success: false,
        };
      }
    })
    .then(() => {
      return {
        loading: false,
        arrayInventario: inventarioFinal,
        success: true,
      };
    })
    .catch(function (reason) {
      return {
        loading: true,
        arrayInventario: inventarioFinal,
        success: false,
      };
    });
}

export const getNewInve = async({ id64 }) => {
  var inventarioFinal = [];
// console.log(id64)
 await axios.get(`https://plankton-app-757hf.ondigitalocean.app/api/?url=https://steamcommunity.com/inventory/${ id64 }/730/2?l=spanish`)
  .then( resp => {
    //console.log(`https://plankton-app-757hf.ondigitalocean.app/api/?url=https://steamcommunity.com/inventory/${ id64 }/730/2?l=spanish`)
    //console.log(resp.data)
    if (resp.data ){
      // console.log('llegue aca')
      
      resp.data.descriptions.forEach(producto => {
        // console.log('proddddd', producto)
        let assetId 
        resp.data.assets.forEach( prodAss => {
          if ( prodAss.classid === producto.classid && prodAss.instanceid === producto.instanceid ){
            assetId = prodAss.assetid
          }
        })

        var lastActionsLink = producto.actions
          ? producto.actions[0].link
          : "";
        if (lastActionsLink != "" || lastActionsLink != undefined) {
          lastActionsLink = lastActionsLink.replace("%owner_steamid%", id64);
          lastActionsLink = lastActionsLink.replace("%assetid%", assetId);
        }

        const item = {
          nombre: producto.market_name,
          // foto:`https://community.cloudflare.steamstatic.com/economy/image/${ producto.icon_url }`,
          foto: producto.icon_url ,
          descripcion: producto.descriptions,
          classid: producto.classid,
          instanceid: producto.instanceid,
          cacheExpiration: (producto.cache_expiration)?producto.cache_expiration:null,
          actionsLink: lastActionsLink,
          tipo: producto.tags[0].localized_tag_name
            ? producto.tags[0].localized_tag_name
            : "",
          assetID: assetId,
          floatValue: ""
        };
        // console.log('item:',item)
        inventarioFinal.push(item);
        
      })

    }

   localStorage.setItem('inveSteam',  JSON.stringify(inventarioFinal))
  
  })
  // console.log('inveFinal',inventarioFinal)
  return {
    loading: false,
    arrayInventario: inventarioFinal,
    success: true,
  };
}



export const getInveWhitSearch = async( search ) => {

  const separateSearch = search.toUpperCase().split(' ')
  
 const  inve = await  GetInventaryTienda( 10000 ).then(resp =>{
      
    const inveFilteredOutSpecialCharacters = resp.filter( itemFiltrado => {
      const nameItem = (itemFiltrado.nombre).replace(/[^a-zA-Z0-9]/g, '').toUpperCase()

      return  nameItem.includes( separateSearch[0] ) &&
              nameItem.includes( separateSearch[1] || '') &&
              nameItem.includes( separateSearch[2] || '') &&
              nameItem.includes( separateSearch[3] || '') &&
              nameItem.includes( separateSearch[4] || '') &&
              nameItem.includes( separateSearch[5] || '') &&
              nameItem.includes( separateSearch[6] || '') &&
              nameItem.includes( separateSearch[7] || '') 
      })

    const inveFilteredWhitSpaces = resp.filter( itemFiltrado => {
        const nameItem = (itemFiltrado.nombre).replace(/[^a-zA-Z0-9]/g, '').toUpperCase()

        return  nameItem.includes( separateSearch[0] ) &&
                nameItem.includes( separateSearch[1] || '') &&
                nameItem.includes( separateSearch[2] || '') &&
                nameItem.includes( separateSearch[3] || '') &&
                nameItem.includes( separateSearch[4] || '') &&
                nameItem.includes( separateSearch[5] || '') &&
                nameItem.includes( separateSearch[6] || '') &&
                nameItem.includes( separateSearch[7] || '')
        // .includes( separateSearch[1] )
      })

    const inveFiltered = resp.filter( itemFiltrado =>{ 
      const nameItem = (itemFiltrado.nombre).toUpperCase()

     return   nameItem.includes( separateSearch[0] ) &&
              nameItem.includes( separateSearch[1] || '') &&
              nameItem.includes( separateSearch[2] || '') &&
              nameItem.includes( separateSearch[3] || '') &&
              nameItem.includes( separateSearch[4] || '') &&
              nameItem.includes( separateSearch[5] || '') &&
              nameItem.includes( separateSearch[6] || '') &&
              nameItem.includes( separateSearch[7] || '')
        // .includes( separateSearch[1] )
      })

      if( inveFilteredOutSpecialCharacters.length >= 1 ){

        console.log('1')
        return(inveFilteredOutSpecialCharacters)
      }else if ( inveFilteredWhitSpaces.length >= 1 ){
        
        console.log('2')
        return(inveFilteredWhitSpaces)
      }else {
        console.log('3')
        return(inveFiltered)
      }


    })
    
    return inve
}
