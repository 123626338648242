import React from "react";
import "./CovertInfo.css";
import Covert1 from "../../../img/Covert-Info-1.jpg";
import Covert2 from "../../../img/Covert-Info-2jpg.jpg";
const CoverInfo = () => {
  return (
    <div className="contenedor-covert-info-padre">
      <img src={Covert1} alt="" />
      <img src={Covert2} alt="" />
    </div>
  );
};
export default CoverInfo;
