import { db } from "../firebase";

export const editarArticulo = (idProducto,estado) => {
  return db
    .collection("tienda")
    .doc(idProducto)
    .update({
      disponible: estado,
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
    });
};

export const eliminarArticulos = async( articulos ) => {

  articulos.map( articulo => {

    db.collection("tienda").doc(articulo.id).delete()
  })
}