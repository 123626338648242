import React from "react";
import Social from "../Home/Social/Social";

const DatosDeUsuarios = () => {
  return (
    <div style={{ padding: 40, height: "70vh" }}>
      <h1>Intrucciones para solicitar el borrado de sus datos</h1>
      <p>
        Si usted desea borrar sus datos almacenados en este sitio debe
        contactarse con nosotros via:
      </p>
      <Social noDispose={false} navBar={true} />
    </div>
  );
};
export default DatosDeUsuarios;
