import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Tienda from "./routes/Tienda/Tienda.jsx";
import Home from "./routes/Home/Home";
import NavBarComponent from "./routes/NavBard/NavBarComponent";
import Administrador from "./routes/Administrador/Administrador";
import VenderSkins from "./routes/VenderSkins/VenderSkins";
import Footer from "./routes/Home/Footer/Footer";
import CarritoState from "./Contexto/Carrito/CarritoState";
import PreguntasFrecuentes from "./routes/PreguntasFrecuentes/PreguntasFrecuentes.jsx";
import Pagar from "./routes/Pagar/Pagar";
import { auth, db } from "./firebase";
import PoliticasDePrivacidad from "./routes/PoliticasDePrivacidad/PoliticasDePrivacidad";
import DatosDeUsuarios from "./routes/PoliticasDePrivacidad/DatosDeUsuarios";

import { GetValorDolar, GetValorDolarComision } from "./Services/GetValorDolar";
import { useContext } from "react";
import { UiContext } from "./Contexto/ui/UiContext";

function App() {
  //"build": "CI=false && react-scripts build", to deploy in vercel
  // ========== ESTADO DE LA APP ============
  const [userAuth, setUserAuth] = useState(false);
  const { dolar, dolarComision } = useContext( UiContext )
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [usuarioData, setUsuarioData] = useState({
    id: "",
    nombreCompleto: "",
    alias: "",
    nacionalidad: "",
    email: "",
    phoneNumber: "",
    photoUrl: "",
    suscripcionActiva: "",
  });
 
  // ========== FIREBASE AUTH ============
  async function buscarEnFireStore(user) {

    const result = await db
      .collection("users")
      .where("id", "==", user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setUsuarioData(doc.data());
          setIsLoading(true);
          setIsDataLoading(true);
        });
      });
  }
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserAuth(true);
        buscarEnFireStore(user);
      } else {
        // No user is signed in.
      }
    });
    if (!userAuth) {
      setIsLoading(true);
    }
  }, [userAuth]);

  useEffect(() => {
    setIsDataLoading(true)
  }, [usuarioData])
  
  useEffect(() => {

    GetValorDolarComision().then( resp =>{
      
      dolarComision( resp ) })

  }, [])
 

  useEffect(() => {

    GetValorDolar().then( resp => {
      dolar(resp)
     
    })

  }, [])


  return (
    <div className="App">
     
        <CarritoState>
          {isLoading ? (
            <Router>
              <NavBarComponent
                data={{
                  usuarioData,
                  userAuth,
                  setUserAuth,
                  setUsuarioData,
                  setIsDataLoading,
                  showDrawer, setShowDrawer
                }}
              />
              <Route exact path="/" exact>
                <Home userAuth={userAuth} />
              </Route>
              <Route exact path="/Sponsors" exact>
                <Home userAuth={userAuth} sponsors={true} />
              </Route>
              <Route exact path="/PoliticasDePrivacidad" exact>
                <PoliticasDePrivacidad />
              </Route>
              <Route exact path="/DatosDeUsuarios" exact>
                <DatosDeUsuarios />
              </Route>

              {isDataLoading ? (
                <div className="App">
                  <Switch>
                    <Route path="/Tienda" exact>
                      <Tienda data={{ usuarioData, userAuth }} />
                    </Route>

                    <Route path="/Administrador" exact>
                      <Administrador data={{ usuarioData, userAuth }} />
                    </Route>

                    <Route path="/VenderSkins" exact>
                      <VenderSkins />
                    </Route>

                    <Route path="/Pagar" exact>
                      <Pagar data={{ usuarioData, userAuth, setShowDrawer }} />
                    </Route>

                    <Route path="/PreguntasFrecuentes" exact>
                      <PreguntasFrecuentes />
                    </Route>
                  </Switch>
                </div>
              ) : (
                <Switch>
                  <Route path="/Tienda" exact>
                    <Home />
                  </Route>

                  <Route path="/Administrador" exact>
                    <Home />
                  </Route>

                  <Route path="/VenderSkins" exact>
                    <VenderSkins />
                  </Route>

                  <Route path="/PreguntasFrecuentes" exact>
                    <PreguntasFrecuentes />
                  </Route>

                  <Route path="/Pagar" exact>
                    <Home />
                  </Route>
                </Switch>
              )}
              <Footer />
            </Router>
          ) : null}
        </CarritoState>
      
    </div>
  );
}

export default App;
