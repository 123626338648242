import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Card,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Path from "../../../Constantes/Constantes";
import ModalAgregar from "../../ModalAgregar/ModalAgregar";
import { CalcularFecha } from "../../Funciones/CalcularFechas";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: 10,
    padding: 10,
    background: "#212121",
    boxShadow: "0px 0px 19px -2px #01ec96",
    fontFamily: "Roboto Mono monospace",
    fontWeight: "700",
    textTransform: "uppercase",
    color: "#fff",
    textShadow: " 1px 1px #01ec9629",
  },
  descripcion: {
    height: 100,
    overflowY: "scroll",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    backgroundColor:
      "linear-gradient(180deg, rgba(0,0,0,0.4598214285714286) 79%, rgba(255,0,0,0.4234068627450981) 91%)",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    width: 60,
    height: 60,
  },
}));

export default function CardAdmin({ itemUnico }) {
  const [showModal, setShowModal] = useState(false);
  const [diasFaltantes, setDiasFaltantes] = useState("");

  useEffect(() => {
    if (itemUnico.cacheExpiration != "") {
      var hoy = new Date();
      var expiration = new Date(itemUnico.cacheExpiration);
      var diff = CalcularFecha(hoy, expiration);
      setDiasFaltantes(diff);
    }
  }, []);

  var item = {
    nombre: "nahuel",
    foto: "unafoto",
    descripcion: { 2: { type: "", value: "" } },
  };
  item = itemUnico;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function cerrarModal() {
    setShowModal(false);
  }

  function handleAgregar(e) {
    e.preventDefault();
    setShowModal(true);
  }

  return (
    <>
      {showModal ? (
        <ModalAgregar cerrarModal={cerrarModal} item={itemUnico} />
      ) : null}
      <Card className={classes.root}>
        <CardHeader
          avatar={<Avatar alt="Emmik" className={classes.avatar}></Avatar>}
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={item.nombre}
          subheader="September 14, 2016"
        />
        <CardMedia
          className={classes.media}
          image={Path.IMAGEN_URL + item.foto}
          title="Paella dish"
        />
        {diasFaltantes != "" ? (
          <div className="item-disponible">
            En {diasFaltantes} {diasFaltantes === 1 ? "dia" : "dias"}
          </div>
        ) : null}

        <CardContent>
          <Typography
            className={classes.descripcion}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {item.descripcion[2] ? item.descripcion[2].value : "No description"}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add " onClick={(e) => handleAgregar(e)}>
            <AddCircleIcon />
          </IconButton>
        </CardActions>
      </Card>
    </>
  );
}
