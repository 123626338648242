import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import "./Drawer.css";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Path from "../../Constantes/Constantes";
import CarritoContext from "../../Contexto/Carrito/CarritoContext";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Link, Redirect } from "react-router-dom";
import { Badge } from "@material-ui/core";
import { UiContext } from "../../Contexto/ui/UiContext";
import { CartIcon } from "../../components/cart/CartIcon";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));

export default function TemporaryDrawer({
  showDrawer,
  setShowDrawer,
  setShowLogin,
  logeado,
  cerrarSesion,
}) {
  const { CartState, removeProductCart} = useContext(CarritoContext);
  const { productsInCart } = CartState 

  const totalPrice = productsInCart.map( prod => prod.precio)
  const precioFinal = totalPrice.reduce((a, b) => a + b, 0);
  

    const { UiState } = useContext( UiContext )
    const { dolar, currency, dolarComision} = UiState
 

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  function abriLogin() {
    setShowDrawer(false);
    setShowLogin(true);
  }

  const removeItemInCart = (item) => {
    removeProductCart(item)
  }
  // function quitarItem(item) {
  //   var newArray = productsInCart;
  //   var i = newArray.indexOf(item);
  //   i !== -1 && newArray.splice(i, 1);
  //   setCarrito(newArray);
  //   setPrecioTotal(precioTotal - parseInt(item?.precio));
  // }
  // var removeItemFromArr = (arr, item) => {
  //   var i = arr.indexOf(item);
  //   i !== -1 && arr.splice(i, 1);
  // };

  // useEffect(() => {}, [carrito]);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      {!logeado ? (
        <List>
          <ListItem button onClick={() => abriLogin()}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
        </List>
      ) : null}

      <Divider />
      {logeado ? (
        <List>
          <ListItem button onClick={() => cerrarSesion()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Cerrar Sesion" />
          </ListItem>

          {productsInCart[0] &&
            <Link className="link-drawer" to="/Pagar">
                <CartIcon 
                  productsInCart={productsInCart} 
                  showPrice
                  dolar={dolar}
                  dolarComision={dolarComision}
                  precioFinal={precioFinal}
                  currency={currency}
                />
            </Link>
          }

          <div className="contenedor-scroll-items-carrito">
            {productsInCart?.map((item) => {
              return (
                <div className="contenedor-carrito-beta">
                  <img src={Path.IMAGEN_URL + item.foto} alt="" />
                  <h1 className="h1-items-carrito">{item.nombre}</h1>
                  <DeleteForeverIcon
                    className="boton-eliminar-carrito"
                    onClick={() => removeItemInCart(item)}
                  />
           
                </div>
              );
            })}
          </div>
        </List>
      ) : null}
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
