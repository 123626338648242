import { types } from '../types'


export const uiReducer  = ( state , action ) =>{

switch ( action.type ) {

    case types.getDolar:
        return {
            ...state,
            dolar: action.payload
        }

    case types.getDolarComision:
        return {
            ...state,
            dolarComision: action.payload
        }
    case types.setCurrency:
        
        return {
            ...state,
            currency: action.payload
        }

    default:
        return state;
}
}