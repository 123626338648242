import React, { useEffect, useState } from "react";
import "./PanelAdminSkins.css";
import Path from "../../../Constantes/Constantes";
import { GetFloat, GetFloatAxios } from "../../../Services/GetFloat";

import { Button, Table, Form, Collapse, Alert } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import { AgregarProducto } from "../../../Services/AgregarProducto";

const PanelAdminSkins = ({ items }) => {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [paggination, setPaggination] = useState({ inicial: 0, hasta: 15 });
  const [pagginationRender, setPagginationRender] = useState({
    inicial: 0,
    hasta: 15,
  });
  const [botonVerMasLoading, setBotonVerMasLoading] = useState(false);
  const [itemsAPublicar, setItemsAPublicar] = useState([]);
  const [alerta, setAlerta] = useState("");

  var datoFloat = 0;

// console.log(itemsAPublicar)t
  async function Agregar() {
    await Promise.all(
      itemsAPublicar.map((item) => {
        AgregarProducto(item).then((res) => {
          if (res) {
            setAlerta("Agregado Correcatamente");
          } else {
            setAlerta(
              "Hubo un problema al agregar ,tal vez el producto ya existe"
            );
          }
        });
      })
    ).then(() => {
      // setLoading(true);
      // setPagginationRender({
      //   inicial: paggination.hasta,
      //   hasta: paggination.hasta,
      // });
      // setBotonVerMasLoading(false);
    });
  }

  async function getFloatItemMethod(item) {
    if (item.actionsLink != undefined || item.actionsLink != "") {
      return await GetFloatAxios(item.actionsLink).then((res) => {
        if (res != "-") {
          return res.floatvalue;
          datoFloat = res.floatvalue;
        } else {
          return "-";
        }
      });
    } else {
      return "-";
    }
  }

  function handleInputAddItem(item, e) {

    if (e.code == "Tab") {
      console.log(item)
      const found = itemsAPublicar.find(
        (element) => element.item.assetID == item.assetID
      );
      if (found == undefined) {
        setItemsAPublicar((itemsAPublicar) => [
          ...itemsAPublicar,

          {
            item,
            precio: [
              document.getElementById(item.assetID + "-" + item.classid).value,
            ],
          },
        ]);
      }
    }
  }

  useEffect(async () => {
    await Promise.all(
      items.slice(paggination.inicial, paggination.hasta).map((item) => {
        if (item.actionsLink != undefined || item.actionsLink != "") {
          return GetFloatAxios(item.actionsLink).then((res) => {
            if (res) {
              if (res != "-") {
                // item.push({ float: res.floatvalue });
                // items[key].set({ float: res.floatvalue });
                item.floatValue = res.floatvalue;
                return res.floatvalue;
              }
            } else {
              return "-";
            }
          });
        }
      })
    ).then(() => {
      setLoading(true);
      setPagginationRender({
        inicial: paggination.hasta,
        hasta: paggination.hasta,
      });
      setBotonVerMasLoading(false);
    });
  }, [paggination]);

  async function verMas() {
    // setLoading(false);

    // setPaggination((paggination) => [...paggination, { hasta,  [paggination.hasta+15] }]);

    setBotonVerMasLoading(true);
    setPaggination({
      inicial: paggination.hasta,
      hasta: paggination.hasta + 15,
    });
  }

  function borrarDeItemsAPublicar(item) {
    var items = itemsAPublicar.filter(function (element) {
      return (
        element.item.classid + element.item.instanceid !==
        item.item.classid + item.item.instanceid
      );
    });

    setItemsAPublicar(items);
  }

  return (
    <div>
      {alerta !== "" ? (
        <Alert style={{ marginTop: 10 }} variant="primary">
          {alerta}
        </Alert>
      ) : null}
      <div className="contenedor-items-a-subir">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          Ver Items agregados
        </Button>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <div className="tabla-admin-panel">
              <Table
                className="tabla-admin-panel"
                striped
                bordered
                hover
                variant="dark"
              >
                <thead>
                  <tr>
                    <th>Imagen</th>
                    <th>Nombre</th>
                    <th>Estado</th>
                    <th>Precio</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsAPublicar.map((item) => {
                    return (
                      <tr>
                        <td>
                          <img
                            className="img-panel"
                            src={
                              item.item.foto
                                ? Path.IMAGEN_URL + item.item.foto
                                // ? item.item.foto
                                : "https://www.webwork.com.ar/images/default.png"
                            }
                          ></img>
                        </td>
                        <td>{item.item.nombre}</td>
                        <td>{item.item.floatValue}</td>
                        <td>{item.precio}</td>
                        <td>
                          <Button onClick={() => borrarDeItemsAPublicar(item)}>
                            Borrar
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Button onClick={() => Agregar()}>Subir a tienda</Button>
          </div>
        </Collapse>
      </div>
      {loading ? (
        <div className="tabla-admin-panel">
          <Table size="sm" striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Imagen</th>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              {items.slice(0, pagginationRender.hasta).map((item) => {
                return (
                  <tr>
                    <td>
                      <img
                        className="img-panel"
                        src={
                          item.foto
                            ? Path.IMAGEN_URL + item.foto
                            // ?  item.foto
                            : "https://www.webwork.com.ar/images/default.png"
                        }
                      ></img>
                    </td>
                    <td>{item.nombre}</td>
                    <td>{item.floatValue}</td>
                    <td>
                      <Form
                        onSubmit={(e) => e.preventDefault()}
                        // onSubmit={(e) => getUserMethod(e, usuarioBuscar.email)}
                      >
                        <Form.Group>
                          <Form.Control
                            type="number"
                            placeholder="Precio"
                            id={item.assetID + "-" + item.classid}
                            onKeyDown={(e) => handleInputAddItem(item, e)}
                          />
                        </Form.Group>
                      </Form>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : null}
      {botonVerMasLoading ? (
        "espere"
      ) : (
        <Button onClick={() => verMas()} variant="primary">
          Ver mas...
        </Button>
      )}
    </div>
  );
};
export default PanelAdminSkins;
