import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Copyright() {
  return (
    <Typography variant="body2" style={{ color: "#D6D6D6" }} align="center">
      {"COPYRIGHT © "}
      <Link
        style={{ color: "#D6D6D6" }}
        href="https://twitter.com/blazerstorecsgo"
      >
        blazer store csgo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
