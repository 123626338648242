import { useSnackbar } from "notistack";




export const MySuccessSnackbar = ({item, history, userAuth},props, ref) => {
  const { closeSnackbar } = useSnackbar()

return(

    <div {...props} ref={ref} >
      {item.nombre}


    
      <button 
        className="ml-3" 
        style={{
          borderRadius: '12px',
          border: 'none',
          backgroundColor: 'white',
          color: '#43a047'
        }}
        onClick={()=>{
          history.push("/Pagar");
          closeSnackbar(ref)
     }}
      >
     ir al carrito
     
      </button>
    

    </div>)
  

}


