import SortIcon from "@material-ui/icons/Sort"
import "./orderMenu.css";
export const OrderMenu = ({ handleOrder }) => {
  return (
    <div className="order-menu ">

        <div 
            className="dropdown" 
            type="button" 
            id="dropdownoreder" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
         
        >

           <span href="">Ordenar <SortIcon  /></span>  

        </div>

            <ul className="dropdown-menu"   aria-labelledby="dropdownoreder">
                <li className='nav-menu-items' onClick={() => handleOrder('Mayor')}>Precio: Mayor a menor</li>
                <li className='nav-menu-items' onClick={() => handleOrder('Menor')}>Precio: Menor a mayor</li>
                <li className='nav-menu-items' onClick={() => handleOrder('A')}>Orden: A-Z </li>
                <li className='nav-menu-items' onClick={() => handleOrder('Z')}>Orden: Z-A </li>
                <li className='nav-menu-items' onClick={() => handleOrder('')}>Sin filtros</li>
            </ul>
    </div>
  )
}
