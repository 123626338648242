import "./PeguntasFrecuentes.css";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import SteamLogo from "../../img/steam-logo.png";
import TwLogo from "../../img/twLogo.png";
import WtspLogo from "../../img/wtspLogo.png";
import InstaLogo from "../../img/instaLogo.png";
import FbLogo from "../../img/fbLogo.png";
import Paths from "../../Constantes/Constantes.js";
import Social from "../Home/Social/Social";
import ImgPagos from "../../img/pagosLogo.png";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    color: "white !important",
  },
  heading: {
    fontSize: "20px",
    flexBasis: "100%",
    flexShrink: 0,
    textShadow: "1px 1px 10px",
  },
  secondaryHeading: {
    fontSize: "16px",
    fontWeight: "400",
    textAlign: "start",
    color: "white !important",
  },
  accordition: {
    background: "#1a1819db",
    color: "white",
    border: "1px solid var(--color-rosa)",
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="contenedor-padre-preguntas">
      <h1>PREGUNTAS FRECUENTES</h1>
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo sé que son confiables?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Compramos y vendemos skins desde 2016, siendo una de las stores
              con mayor trayectoria de Argentina. Tenemos más de 7.000
              transacciones realizadas satisfactoriamente. De todos modos, podés
              consultarle a nuestros SPONSORS por nosotros, seguro conoces
              alguno! También podés revisar las opiniones de nuestros clientes
              en{" "}
              <a
                href="https://www.facebook.com/blazerstorecsgo/reviews"
                target="blank"
              >
                Facebook
              </a>
              , historias de{" "}
              <a
                href="https://www.instagram.com/stories/highlights/18165486841062242/"
                target="blank"
              >
                Instagram
              </a>{" "}
              ó en <a href="/#SponsorId">Twitter</a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo vender mis skins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Es muy sencillo, seguí los pasos en esta{" "}
              <Link to="/VenderSkins">pagina.</Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo comprar skins?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Podés comprar skins yendo a la sección "Tienda", seleccionando los
              items con el botón "Carrito" y siguiendo los pasos , podras ver
              todo el carrito en la esquina superior derecha de tu pantalla.{" "}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              ¿Dónde consigo mi trade link?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Haz{" "}
              <a href="https://steamcommunity.com/id/me/tradeoffers/privacy">
                CLICK AQUI
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              Métodos de pago:
            </Typography>
          </AccordionSummary>
          <div className="contenedor-img-pagos">
            <img className="img-pagos" src={ImgPagos} alt="a" />
          </div>
        </Accordion>

        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              ¿Cuánto puede demorar mi pago?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Generalmente las tranferencias son inmediatas. Puede que por
              factores externos a Blazer Store, los pagos demoren hasta 72hs
              hábiles en acreditarse. Los items se enviarán una vez el dinero
              haya sido ACREDITADO en nuestra cuenta bancaria, sin excepciones.
              Si sucede esto, lamentamos la situación y esperamos la comprensión
              para con nosotros.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              ¿El stock siempre está actualizado?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Los items publicados en nuestra tienda están sincronizados con
              nuestros inventarios, en caso de haber realizado una compra y no
              poseer el item por algún error, el cliente podrá optar por:
              <ul className="ul-itemsv2">
                <li>Pedir un reembolso del dinero</li>
                <li>Elegir otro item de igual valor</li>
                <li>Elegir varios items que sumen el valor total</li>
                <li>
                  Elegir otro item de menor valor y recibir la diferencia en
                  dinero
                </li>
                <li>
                  Elegir otro item de mayor valor y abonar la diferencia en
                  dinero
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              Ya compré... ¿y ahora que hago?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Luego de realizada la compra, es necesario que el cliente se
              comunique con nosotros por alguna de nuestras redes sociales, para
              coordinar la entrega.
              <div className="contenedor-social">
                <Social navBar={true} colorNegro={true} />
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              ¿Cuánto demoran en entregar los items?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Siempre intentaremos entregar los items lo antes posible, siendo
              así que la mayoría de las veces tardamos minutos en hacerlo. De
              todos modos, las entregas pueden demorar hasta 24 horas hábiles,
              siempre y cuando la fecha de entrega del item sea INMEDIATA. En
              caso de haber algún error con la fecha de desbloqueo, el cliente
              podrá optar por:
              <ul className="ul-items">
                <li>Esperar la fecha de desbloqueo del item</li>
                <li>Elegir otro item de igual valor</li>
                <li>Elegir varios items que sumen el valor total</li>
                <li>
                  Elegir otro item de menor valor y recibir la diferencia en
                  dinero
                </li>
                <li>
                  Elegir otro item de mayor valor y abonar la diferencia en
                  dinero
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              ¿Hacen reembolsos?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              No hacemos reembolsos de items ya entregados. Si pagaste por un
              item y todavía no lo recibiste, podés pedir un reembolso, el cual
              será del 95% del monto total. Recordá que cada transferencia tiene
              un costo de operación que no se puede reecuperar, por ende pedimos
              compromiso al momento de comprar y transferir.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel11"}
          onChange={handleChange("panel11")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              ¿Cuáles son sus cuentas?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Solamente tenemos las cuentas descriptas a continuación, nunca nos
              vamos a contactar por fuera de las mismas, porfavor revisar bien
              antes de realizar una operación por su seguridad!
              <ul className="ul-itemsv3">
                <li>
                  <img src={SteamLogo} className="img-cuentas-pf" alt="" />
                  <a href="https://steamcommunity.com/id/emmik" target="blank">
                    Cuenta primaria
                  </a>
                </li>
                <li>
                  <img src={SteamLogo} className="img-cuentas-pf" alt="" />

                  <a
                    href="https://steamcommunity.com/id/blazerstorecsgo"
                    target="blank"
                  >
                    Cuenta secundaria
                  </a>
                </li>
                <li>
                  <img src={TwLogo} className="img-cuentas-pf" alt="" />
                  <a href="https://twitter.com/blazerstorecsgo" target="blank">
                    Twitter
                  </a>
                </li>
                <li>
                  <img src={FbLogo} className="img-cuentas-pf" alt="" />
                  <a href="https://facebook.com/blazerstorecsgo" target="blank">
                    Facebook
                  </a>
                </li>
                <li>
                  <img src={InstaLogo} className="img-cuentas-pf" alt="" />
                  <a
                    href="https://instagram.com/blazerstorecsgo"
                    target="blank"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <img src={WtspLogo} className="img-cuentas-pf" alt="" />
                  <a
                    href="https://api.whatsapp.com/message/JPG7W4I2LY4YJ1"
                    target="blank"
                  >
                    Whatsapp
                  </a>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
          className={classes.accordition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo solicito el borrado de mis datos?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.secondaryHeading}>
              Si usted desea{" "}
              <a href={Paths.SITE_URL + "/DatosDeUsuarios"} target="blank">
                borrar
              </a>{" "}
              sus datos almacenados en este sitio debe contactarse con nosotros
              via:
              <div className="contenedor-social">
                <Social navBar={true} colorNegro={true} />
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
